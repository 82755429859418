import {useCallback, useMemo} from 'react';
import TMapSender from '@lcc/tmap-inapp';
import {EDateFormat} from 'types/DateTime';
import {ERecentItemType, TRecentItem} from 'types/Search';
import {useAppDispatch} from 'ducks/hooks';
import {
  deletePublicTransRecentDestination,
  deleteRecentDestination,
  fetchUserData,
} from 'ducks/userInfo/slice';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import useRecentQuery from 'hooks/useRecentQuery';
import useAddress from 'hooks/useAddress';
import {getSafeDateFormat} from 'utils/date';
import {parsePoiInfoToNavInfo} from 'utils/search';
import {sendSearchClickLog} from 'utils/logManager';
import SearchListIcon from 'components/search/SearchListIcon';
import {IcExit} from '../@tmds/icons/v1.2/IcExit';

import s from 'styles/components/search/SearchRecentItem.module.scss';

type TRecentItemProps = TRecentItem & {onClick?: () => void};

enum EDestinationType {
  SEARCH_RESULT = 'SEARCH_RESULT',
  ROUTE = 'ROUTE',
  ROUTE_PUBLIC_TRANSPORT = 'ROUTE_PUBLIC_TRANSPORT',
  DETAIL_PAGE = 'DETAIL_PAGE',
  BUS_STATION_DETAIL = 'BUS_STATION_DETAIL',
}

const DEST_MAP: Record<ERecentItemType, EDestinationType> = {
  // 검색결과
  [ERecentItemType.QUERY]: EDestinationType.SEARCH_RESULT,

  // 경로요약
  [ERecentItemType.POI]: EDestinationType.ROUTE,
  [ERecentItemType.HOME]: EDestinationType.ROUTE,
  [ERecentItemType.OFFICE]: EDestinationType.ROUTE,
  [ERecentItemType.FAVORITE]: EDestinationType.ROUTE,

  // 대중교통 경로요약
  [ERecentItemType.PUBLIC_POI]: EDestinationType.ROUTE_PUBLIC_TRANSPORT,

  // 지하철역상세
  [ERecentItemType.SUBWAY]: EDestinationType.DETAIL_PAGE,
  [ERecentItemType.FAVORITE_SUBWAY]: EDestinationType.DETAIL_PAGE,

  // 정류장상세
  [ERecentItemType.BUS_STATION]: EDestinationType.BUS_STATION_DETAIL,
  [ERecentItemType.FAVORITE_STATION]: EDestinationType.BUS_STATION_DETAIL,
};

enum EDeleteType {
  QUERY = 'QUERY',
  POI = 'POI',
  PUBLIC_TRANS_POI = 'PUBLIC_TRANS_POI',
}

const DELETE_MAP: Record<ERecentItemType, EDeleteType> = {
  // 검색결과
  [ERecentItemType.QUERY]: EDeleteType.QUERY,

  // poi
  [ERecentItemType.POI]: EDeleteType.POI,
  [ERecentItemType.HOME]: EDeleteType.POI,
  [ERecentItemType.OFFICE]: EDeleteType.POI,
  [ERecentItemType.FAVORITE]: EDeleteType.POI,

  // 대중교통
  [ERecentItemType.PUBLIC_POI]: EDeleteType.PUBLIC_TRANS_POI,
  [ERecentItemType.SUBWAY]: EDeleteType.PUBLIC_TRANS_POI,
  [ERecentItemType.BUS_STATION]: EDeleteType.PUBLIC_TRANS_POI,
  [ERecentItemType.FAVORITE_SUBWAY]: EDeleteType.PUBLIC_TRANS_POI,
  [ERecentItemType.FAVORITE_STATION]: EDeleteType.PUBLIC_TRANS_POI,
};

const SearchRecentItem = ({onClick, ...data}: TRecentItemProps) => {
  const dispatch = useAppDispatch();
  const {removeItem} = useRecentQuery();
  const {fullAddress} = useAddress(data.poiInfo);
  const {
    moveToSearch,
    moveToSelectDestinationAction,
    moveToPublicTransportDestination,
    moveToBusStationDetail,
    moveToSelectDetailAction,
  } = useMoveToTarget({
    from: EFromType.RECENT,
  });

  const navInfo = useMemo(() => {
    const poiInfo = data.poiInfo as any;
    const hasNavPoint = poiInfo?.centerX && poiInfo?.navX;

    if (hasNavPoint) {
      const address = fullAddress || poiInfo.customName;
      return parsePoiInfoToNavInfo({
        pkey: poiInfo.pkey || '',
        poiId: poiInfo.poiId || '',
        navSeq: poiInfo.navSeq || '',
        navX: `${poiInfo?.navX || ''}`,
        navY: `${poiInfo?.navY || ''}`,
        centerX: `${poiInfo?.centerX || ''}`,
        centerY: `${poiInfo?.centerY || ''}`,
        rpFlag: poiInfo.rpFlag,
        poiName: poiInfo.customName || address || data.name,
        address,
        tel: '',
        publicTransportType: poiInfo.publicTransportType,
        stationId: poiInfo.stationId || poiInfo.transportId,
      });
    }

    return null;
  }, [data, fullAddress]);

  const deleteDestination = useCallback(
    async (item) => {
      // TBD dispatch 타입 수정
      const result: any = await dispatch(deleteRecentDestination({items: [item]}));

      if (result.payload.success) {
        TMapSender.setDirtyRecent();
        dispatch(fetchUserData());
      } else {
        TMapSender.makeToast(result.payload.message || '최근목적지 삭제를 실패하였습니다.');
      }
    },
    [dispatch]
  );

  const deletePublicTransDestination = useCallback(
    async (item) => {
      if (!item.transportId) {
        // transportId 없이 delete하는 경우 전체 삭제됨.
        // https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/533434336/API#Header.2
        return;
      }
      const result = await deletePublicTransRecentDestination({transportIds: [item.transportId]});

      if (result.success) {
        TMapSender.setDirtyRecent();
        dispatch(fetchUserData());
      } else {
        TMapSender.makeToast(result.message || '최근목적지 삭제를 실패하였습니다.');
      }
    },
    [dispatch]
  );

  const DESTINATION_HANDLER_MAP: Record<EDestinationType, () => any> = useMemo(
    () => ({
      [EDestinationType.SEARCH_RESULT]: () => moveToSearch(data.name),
      [EDestinationType.DETAIL_PAGE]: () => navInfo && moveToSelectDetailAction(navInfo),
      [EDestinationType.ROUTE]: () => navInfo && moveToSelectDestinationAction(navInfo),
      [EDestinationType.ROUTE_PUBLIC_TRANSPORT]: () =>
        navInfo && moveToPublicTransportDestination(navInfo),
      [EDestinationType.BUS_STATION_DETAIL]: () => navInfo && moveToBusStationDetail(navInfo),
    }),
    [
      data,
      navInfo,
      moveToSearch,
      moveToBusStationDetail,
      moveToSelectDetailAction,
      moveToSelectDestinationAction,
      moveToPublicTransportDestination,
    ]
  );

  const handleClick = useCallback(
    (e) => {
      const destinationType = DEST_MAP[data.type];
      const handler = DESTINATION_HANDLER_MAP[destinationType];

      onClick?.();
      handler?.();
    },
    [DESTINATION_HANDLER_MAP, data, onClick]
  );

  const DELETE_HANDLER_MAP: Record<EDeleteType, () => any> = useMemo(
    () => ({
      [EDeleteType.QUERY]: () => removeItem(data.name),
      [EDeleteType.POI]: () => deleteDestination(data),
      [EDeleteType.PUBLIC_TRANS_POI]: () => deletePublicTransDestination(data),
    }),
    [data, removeItem, deleteDestination, deletePublicTransDestination]
  );

  const handleClickDelete = useCallback(
    (e) => {
      e.stopPropagation();

      const deleteType = DELETE_MAP[data.type];
      const handler = DELETE_HANDLER_MAP[deleteType];

      sendSearchClickLog('tap.history_delete');
      handler?.();
    },
    [DELETE_HANDLER_MAP, data]
  );

  return (
    <li key={data.name} className={s.wrap}>
      <div className={s.item} onClick={handleClick}>
        <SearchListIcon srcType={data.type} className={s.icon} />
        <p className={s.meta}>
          <span className={s.name}>{data.name}</span>
          <span className={s.date}>{getSafeDateFormat(data.date, EDateFormat.MMdd)}</span>
        </p>
      </div>
      <button className={s.delete} onClick={handleClickDelete}>
        <IcExit width={16} height={16} color={'iconQuaternary'} />
      </button>
    </li>
  );
};

export default SearchRecentItem;
