import {useCallback, useMemo} from 'react';
import ImageLoader from '../../ImageLoader';
import DiscoveryPoiItemInfo from './DiscoveryPoiItemInfo';
import {TDiscoveryItem} from '../../../ducks/tplacehome/types';
import {setComma} from '../../../utils/formatter';
import {ReactComponent as IcStar} from 'resource/images/ic_star_stroke.svg';
import {ReactComponent as IcStarFill} from 'resource/images/ic_star_stroke_fill.svg';

import s from '../../../styles/components/tplacehome/TPlaceDiscoveryColumnPoiItem.module.scss';
import usePlaceHome from 'hooks/usePlaceHome';
import TmdsTag from 'components/@tmds/TmdsTag';

type TProps = {
  data: TDiscoveryItem;
  thumbHeight: number;
  isLowestPrice?: boolean;
  onClickDetail?: () => void;
};

const DiscoveryColumnPoiItem = ({data, thumbHeight, isLowestPrice, onClickDetail}: TProps) => {
  const {poiName, imageUrl, reviewDto, svcCategoryName, distance, accommodationInfo} = data;
  const {reviewCount, reviewGrade} = reviewDto;

  const placehomeHook = usePlaceHome();
  const isFavorite = useMemo(() => placehomeHook.checkFavorite(data), [data, placehomeHook]);
  const handleToggleFavorite = useCallback(() => {
    placehomeHook.sendEvent('tap.bookmark', {
      pkey: data.pkey,
      on: isFavorite,
    });
    placehomeHook.toggleFavorite(data);
  }, [data, isFavorite, placehomeHook]);

  const handleMoveToDetail = useCallback(() => {
    onClickDetail?.();
    placehomeHook.openPoiDetail(data);
  }, [data, onClickDetail, placehomeHook]);

  const lowestAccommodation = useMemo(() => {
    if (!isLowestPrice || !accommodationInfo?.onda) {
      return null;
    }

    const info = accommodationInfo?.onda;
    const price = `${setComma(info.price)}원 ~`;
    if (info.isTmapSpecialPrice) {
      return {
        label: '티맵단독',
        price,
        bg: 'tmobiBlue400',
      };
    } else if (info.isLowestPrice) {
      return {
        label: '최저가보장',
        price,
        bg: 'tmobiPink400',
      };
    } else {
      return null;
    }
  }, [accommodationInfo?.onda, isLowestPrice]);

  return (
    <div className={s.wrap}>
      <button className={s.content} onClick={handleMoveToDetail}>
        <div className={s.thumb} style={{height: thumbHeight}}>
          <ImageLoader src={imageUrl} toggleClass={s.fade_in} alt={`${poiName} 이미지`} />
          {lowestAccommodation && (
            <TmdsTag
              label={lowestAccommodation.label}
              backgroundColor={lowestAccommodation.bg}
              color={'wbWhite'}
              fontSize={12}
              className={s.tag}
              filled
            />
          )}
        </div>
        <div className={s.info}>
          <em className={s.name}>{poiName}</em>
          <span className={s.category}>{svcCategoryName}</span>
          {lowestAccommodation ? (
            <em className={s.price}>{lowestAccommodation.price}</em>
          ) : (
            <DiscoveryPoiItemInfo
              starPoint={reviewGrade}
              reviewCount={reviewCount}
              distance={distance}
            />
          )}
        </div>
      </button>
      <button
        type="button"
        className={s.favorite}
        style={{top: `${thumbHeight - 32}px`}}
        onClick={handleToggleFavorite}
      >
        {isFavorite ? <IcStarFill /> : <IcStar />}
      </button>
    </div>
  );
};

export default DiscoveryColumnPoiItem;
