import classNames from 'classnames';
import actions from 'ducks/actions';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {ETPlaceTab} from 'ducks/tplacehome/types';
import {useOnce} from 'hooks/useOnce';
import usePlaceHome from 'hooks/usePlaceHome';

import {useCallback, useMemo, useRef, useState} from 'react';

import s from 'styles/components/tplacehome/TPlaceTab.module.scss';

const PLACE_TAB_TEXT: Record<ETPlaceTab, string> = {
  [ETPlaceTab.AROUND_ME]: '내 주변',
  [ETPlaceTab.DISCOVERY]: '발견',
};

const TPlaceTab = () => {
  const {tplacehome, layout} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const buttonWrapRef = useRef<HTMLDivElement>(null);
  const [inited, setInited] = useState(false);
  const placehomeHook = usePlaceHome();

  const handleClickTab = useCallback(
    (value) => {
      placehomeHook.sendEvent(
        value === ETPlaceTab.AROUND_ME ? 'tap.tab_around' : 'tap.tab_discover'
      );
      dispatch(actions.tplacehome.setCurrentTab(value));
    },
    [dispatch, placehomeHook]
  );

  const bgStyle = useMemo(() => {
    if (!inited) {
      return {};
    }
    const buttonWrapEl = buttonWrapRef.current;
    const targetEl = buttonWrapEl?.querySelector(
      `[data-name=${tplacehome.currentTab}`
    ) as HTMLButtonElement;
    if (buttonWrapEl && targetEl) {
      return {
        left: `${targetEl.offsetLeft}px`,
        width: `${targetEl.offsetWidth}px`,
        opacity: 1,
      };
    } else {
      return {
        left: '0',
        width: '94px',
        opacity: 1,
      };
    }
  }, [inited, tplacehome.currentTab]);

  useOnce(tplacehome.aroundMeData.result.loaded || tplacehome.aroundMeData.result.error, () => {
    setInited(true);
  });

  return (
    <div className={s.wrap}>
      {!layout.appSize.isLandscape && (
        <div className={s.handle_wrap}>
          <div className={s.handle} />
        </div>
      )}

      <div className={s.button_wrap} ref={buttonWrapRef}>
        {Object.values(ETPlaceTab).map((v, index) => (
          <button
            key={v}
            data-name={v}
            className={classNames(s.button, {
              [s.is_selected]: inited && tplacehome.currentTab === v,
            })}
            onClick={() => handleClickTab(v)}
          >
            {PLACE_TAB_TEXT[v]}
          </button>
        ))}
        <div className={s.selected_bg} style={bgStyle} />
      </div>
    </div>
  );
};

export default TPlaceTab;
