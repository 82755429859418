import semver from 'semver';
import packageInfo from '../../package.json';
import {EMarkerType} from 'types/Map';
import {
  EAppExtraCode,
  EAppRequestMode,
  EAppRequestTypeNew,
  EDetailButtonType,
  ERPFlag,
  ERPFlagCode,
  TPoiDetailParam,
} from 'types/App';
import {v4 as uuidv4} from 'uuid';

import {RP_FLAG_CODES} from 'constant/Tmap';
import MarkerImages from 'resource/pubImages/markers/@markerPath';

import {getPlaceDetailUrl} from 'utils/url';
import ua from 'utils/uaParser';
import LocalStorage from '@lcc/web-storage';
import {NEW_HOME_KEY} from './dev';
import {getAllParams} from './apis';
import {ESearchWebHost} from 'constant/Path';
import {isRtgEnv} from 'constant/Env';
import {ERecentItemType} from 'types/Search';

let Tmapv2: any = {};
const SKBESSEL_TO_BESSEL = 36000;

const personalMarkerSize = {
  width: 22,
  height: 22,
};

const personalActiveMarkerSize = {
  width: 25,
  height: 25,
};

const MarkerStyles = {
  [EMarkerType.ACTIVE]: {
    imageSrc: MarkerImages.mapmarker.place.select.day,
    nightImageSrc: MarkerImages.mapmarker.place.select.night,
    width: 39,
    height: 53,
  },
  [EMarkerType.INACTIVE]: {
    imageSrc: MarkerImages.mapmarker.place.normal.day,
    nightImageSrc: MarkerImages.mapmarker.place.normal.night,
    width: 16,
    height: 16,
  },
  [EMarkerType.CURRENT_POSITION]: {
    imageSrc: MarkerImages.marker.TRACK.POINT,
    width: 74,
    height: 74,
  },
  [EMarkerType.RECENT_DESTINATION]: {
    imageSrc: MarkerImages.mapmarker.recent,
    ...personalMarkerSize,
  },
  [EMarkerType.ACTIVE_RECENT_DESTINATION]: {
    imageSrc: MarkerImages.mapmarker.recent,
    ...personalActiveMarkerSize,
  },
  [EMarkerType.FAVORITE]: {imageSrc: MarkerImages.mapmarker.favorite.poi, ...personalMarkerSize},
  [EMarkerType.ACTIVE_FAVORITE]: {
    imageSrc: MarkerImages.mapmarker.favorite.poi,
    ...personalActiveMarkerSize,
  },
  [EMarkerType.FAVORITE_HOME]: {
    imageSrc: MarkerImages.mapmarker.favorite.home,
    ...personalMarkerSize,
  },
  [EMarkerType.ACTIVE_FAVORITE_HOME]: {
    imageSrc: MarkerImages.mapmarker.favorite.home,
    ...personalActiveMarkerSize,
  },
  [EMarkerType.FAVORITE_OFFICE]: {
    imageSrc: MarkerImages.mapmarker.favorite.office,
    ...personalMarkerSize,
  },
  [EMarkerType.ACTIVE_FAVORITE_OFFICE]: {
    imageSrc: MarkerImages.mapmarker.favorite.office,
    ...personalActiveMarkerSize,
  },
  [EMarkerType.FAVORITE_PUBLIC_TRANS]: {
    imageSrc: MarkerImages.mapmarker.favorite.public,
    ...personalMarkerSize,
  },
  [EMarkerType.ACTIVE_FAVORITE_PUBLIC_TRANS]: {
    imageSrc: MarkerImages.mapmarker.favorite.public,
    ...personalActiveMarkerSize,
  },

  [EMarkerType.ACTIVE_BUS_STATION]: {
    imageSrc: MarkerImages.mapmarker.busstop.select,
    nightImageSrc: MarkerImages.mapmarker.busstop.select,
    width: 40,
    height: 53,
  },
  [EMarkerType.ACTIVE_SUBWAY]: {
    imageSrc: MarkerImages.mapmarker.subway.select,
    nightImageSrc: MarkerImages.mapmarker.subway.select,
    width: 40,
    height: 53,
  },
  [EMarkerType.NONE]: {imageSrc: null},
};

export const convertSkBesseltoWgs84 = (x: string | number, y: string | number) => {
  Tmapv2 = window.Tmapv2 || {};

  const xnum = parseInt(`${x}`, 10) || 0;
  const ynum = parseInt(`${y}`, 10) || 0;

  if (Tmapv2.Projection) {
    const wgs84points = Tmapv2.Projection.convertBesselGEOToWGS84GEO(
      new Tmapv2.LatLng(ynum / SKBESSEL_TO_BESSEL, xnum / SKBESSEL_TO_BESSEL)
    );
    return [wgs84points.lng(), wgs84points.lat()];
  }

  return [0, 0];
};

export const getMarkerStyles = (type) => MarkerStyles[type];

export const getPoiDetailLandingParam = (poiData, otherParams) => {
  const param: TPoiDetailParam = {
    poiId: `${poiData.poiId || ''}`,
    navX: `${poiData.navX || ''}`,
    navY: `${poiData.navY || ''}`,
    pkey: `${poiData.pkey || ''}`,
    navSeq: `${isNaN(poiData.navSeq) ? '' : poiData.navSeq}`,
    mapX: `${poiData.mapX || poiData.centerX || ''}`,
    mapY: `${poiData.mapY || poiData.centerY || ''}`,
    extra: otherParams.extra,
    reqMode: otherParams.reqMode,
    reqType: otherParams.reqType,
    sendCar: otherParams.sendCar,
    favorite: otherParams.favorite,
    tailParam: otherParams.tailParam,
    popupId: otherParams?.popupId,
    wait: otherParams?.wait,
    reserve: otherParams?.reserve,
  };

  const url = getPlaceDetailUrl(param);

  return {url, param};
};

export const rpFlagNumToCode = (numCode?: ERPFlag) => {
  if (numCode === undefined) {
    return undefined;
  }

  const matchItem = RP_FLAG_CODES.find((n) => n.numCode === numCode);

  return matchItem?.strCode || undefined;
};

export const rpFlagCodeToNum = (strCode?: ERPFlagCode) => {
  if (!strCode) {
    return undefined;
  }

  const matchItem = RP_FLAG_CODES.find((n) => n.strCode === strCode);

  return matchItem?.numCode || undefined;
};

export const isNewApp = () => {
  const isOver10 = ua.isInApp && ua.tmapAppVersion.startsWith('10.');
  if (isOver10) {
    return true;
  }
  return !!LocalStorage.getItem(NEW_HOME_KEY);
};

export const isFromRoute = (reqMode) => {
  return [
    EAppRequestMode.ROUTE,
    EAppRequestMode.PUBLIC_TRANSPORT_ROUTE,
    EAppRequestMode.WALK_ROUTE,
  ].includes(reqMode);
};

export const isFromReport = (reqMode, extra, query) => {
  return (
    EAppRequestMode.REPORT === reqMode ||
    ([EAppRequestMode.MAIN, EAppRequestMode.OTHER_SERVICE].includes(reqMode as EAppRequestMode) &&
      query &&
      extra !== EAppExtraCode.SEARCH_T_MAP)
  );
};

export const isFromEventPage = (reqMode) => {
  return EAppRequestMode.EVENT_PAGE === reqMode;
};

const DEFAULT_BUTTONS = [
  EDetailButtonType.DEPARTURE,
  EDetailButtonType.VIA,
  EDetailButtonType.DESTINATION,
];

const EXTRA_BUTTON_MAP = {
  [EAppExtraCode.SETTING_GO_HOME]: [EDetailButtonType.CONFIRM],
  [EAppExtraCode.SETTING_GO_OFFICE]: [EDetailButtonType.CONFIRM],
  [EAppExtraCode.SETTING_FAVORITE]: [EDetailButtonType.CONFIRM],
};

const REQ_TYPE_BUTTON_MAP = {
  [EAppRequestTypeNew.DESTINATION]: [EDetailButtonType.DESTINATION],
  // [EAppRequestTypeNew.MIN_WAYPOINT]: [EDetailButtonType.VIA],
  // [EAppRequestTypeNew.MAX_WAYPOINT]: [EDetailButtonType.VIA],
  [EAppRequestTypeNew.DEPARTURE]: [EDetailButtonType.DEPARTURE],
  [EAppRequestTypeNew.ALL_DATA]: [EDetailButtonType.NONE],
};

const REQ_MODE_BUTTON_MAP = {
  [EAppRequestMode.MAIN]: DEFAULT_BUTTONS,
  [EAppRequestMode.ROUTE]: DEFAULT_BUTTONS,
  [EAppRequestMode.DRIVING]: [EDetailButtonType.VIA, EDetailButtonType.DEPARTURE],
  [EAppRequestMode.WIDGET]: DEFAULT_BUTTONS,
  [EAppRequestMode.FAVORITE]: [EDetailButtonType.CONFIRM],
  [EAppRequestMode.PUBLIC_TRANSPORT]: [EDetailButtonType.DEPARTURE, EDetailButtonType.DESTINATION],
  [EAppRequestMode.PUBLIC_TRANSPORT_ROUTE]: [
    EDetailButtonType.DEPARTURE,
    EDetailButtonType.DESTINATION,
  ],
  [EAppRequestMode.WALK_ROUTE]: [EDetailButtonType.DEPARTURE, EDetailButtonType.DESTINATION],
  [EAppRequestMode.REPORT]: [EDetailButtonType.CONFIRM],
  [EAppRequestMode.EVENT_PAGE]: [EDetailButtonType.CONFIRM],
  [EAppRequestMode.TPLACE_HOME]: DEFAULT_BUTTONS,
};

// extra → reqType → reqMode
export const getDetailPageActionButton = ({extra, reqType, reqMode}) => {
  const extraButtons = EXTRA_BUTTON_MAP[extra];

  if (extraButtons?.length > 0) {
    return extraButtons;
  }

  const isViaReqType =
    EAppRequestTypeNew.MAX_WAYPOINT >= reqType && reqType >= EAppRequestTypeNew.MIN_WAYPOINT;

  if (isViaReqType) {
    return [EDetailButtonType.VIA];
  }

  const reqTypeButtons = REQ_TYPE_BUTTON_MAP[reqType];

  if (reqTypeButtons?.length > 0) {
    return reqTypeButtons;
  }

  const reqModeButtons = REQ_MODE_BUTTON_MAP[reqMode];

  if (reqModeButtons?.length > 0) {
    return reqModeButtons;
  }

  return DEFAULT_BUTTONS;
};

export const getAppActionButton = ({extra, reqType, reqMode}) => {
  const actionButton = getDetailPageActionButton({extra, reqType, reqMode});

  if (
    actionButton.includes(EDetailButtonType.DEPARTURE) &&
    actionButton.includes(EDetailButtonType.DESTINATION)
  ) {
    return EDetailButtonType.DESTINATION;
  }

  return EDetailButtonType.CONFIRM;
};

export const getCalloutActionButtons = ({extra, reqType, reqMode}) => {
  const actionButton = getDetailPageActionButton({extra, reqType, reqMode});

  if (
    actionButton.includes(EDetailButtonType.DEPARTURE) &&
    actionButton.includes(EDetailButtonType.DESTINATION)
  ) {
    return actionButton;
  }

  return [EDetailButtonType.CONFIRM];
};

// 10.1.0 부터 지원
export const isOndaSupportVersion = () => {
  if (!ua.tmapAppVersion) {
    return false;
  }

  return semver.gte(ua.tmapAppVersion, '10.1.0');
};

// 10.4.0 부터 지원
export const isPublicTransVersion = () => {
  if (!ua.tmapAppVersion) {
    return false;
  }

  return semver.gte(ua.tmapAppVersion, '10.4.0');
};

export const isOver10_6Version = () => {
  if (!ua.tmapAppVersion) {
    return false;
  }

  return semver.gte(ua.tmapAppVersion, '10.6.0');
};

export const getCurrentPackageVersion = () => {
  return packageInfo.version;
};

export const getRedirectFromInParam = () => {
  const pageParams = getAllParams();
  const redirectFrom = pageParams?.redirect_from || '';
  const isFromProdInRtg =
    isRtgEnv && (redirectFrom === ESearchWebHost.PROD_DOMAIN || redirectFrom === '');
  const isFromStageInRtg = isRtgEnv && redirectFrom === ESearchWebHost.STAGE_DOMAIN;
  const isFromDevInRtg = isRtgEnv && redirectFrom === ESearchWebHost.DEV_DOMAIN;

  return {
    isFromProdInRtg,
    isFromStageInRtg,
    isFromDevInRtg,
    redirectFrom,
  };
};

export const addPersonalPoiKey = (item) =>
  item
    ? {
        ...item,
        personalPoiKey: uuidv4(),
      }
    : undefined;

export const getRecentItemType = ({item, home, office, favorites}) => {
  if (
    home &&
    (item.pkey === home.pkey || item.poiId === home.poiId || item.customName === home.customName)
  ) {
    return ERecentItemType.HOME;
  }

  if (
    office &&
    (item.pkey === office.pkey ||
      item.poiId === office.poiId ||
      item.customName === office.customName)
  ) {
    return ERecentItemType.OFFICE;
  }

  if (
    favorites &&
    favorites.some(
      (fav) =>
        fav.pkey === item.pkey || fav.poiId === item.poiId || fav.customName === item.customName
    )
  ) {
    return ERecentItemType.FAVORITE;
  }

  return ERecentItemType.POI;
};
