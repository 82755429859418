import {MutableRefObject, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {ESearchTabs} from '../../types/Search';
import {useSearchMainTab} from '../../hooks/useSearchMainTab';
import SearchMainTabCoachMark from './SearchMainTabCoachMark';
import {sendSearchClickLog} from '../../utils/logManager';

import s from '../../styles/pages/SearchMainPage.module.scss';

type TProps = {
  wrapper: MutableRefObject<HTMLDivElement | null>;
  activeTab: any;
  isRedDot?: boolean;
  updateRedDotList: (itemName: string) => void;
};

const SearchMainTab = ({wrapper, activeTab, isRedDot, updateRedDotList}: TProps) => {
  const {changeTab} = useSearchMainTab();

  const [unReadRedDotItem, setUnReadRedDotItem] = useState(false);

  const updateRedDot = useCallback(() => {
    updateRedDotList('TAB_RECOMMEND');
    setUnReadRedDotItem(false);
  }, [updateRedDotList]);

  const handleClick = useCallback(
    (tab: ESearchTabs) => {
      sendSearchClickLog('tap.search_main', {
        tapname: ESearchTabs.RECENT === tab ? '최근기록' : '추천',
      });
      wrapper.current?.scrollTo(0, 0);
      changeTab(tab, {pushHistory: false});
    },
    [wrapper, changeTab]
  );

  useEffect(() => {
    setUnReadRedDotItem(!!isRedDot);
  }, [isRedDot]);

  useEffect(() => {
    if (activeTab === ESearchTabs.RECOMMEND && unReadRedDotItem) {
      updateRedDot();
    }
  }, [activeTab, unReadRedDotItem, updateRedDot]);

  return (
    <div className={s.tab_wrap}>
      <button
        className={classNames(s.item, {[s.active]: activeTab === ESearchTabs.RECENT})}
        onClick={() => handleClick(ESearchTabs.RECENT)}
      >
        최근기록
      </button>
      <button
        className={classNames(s.item, {[s.active]: activeTab === ESearchTabs.RECOMMEND})}
        onClick={() => handleClick(ESearchTabs.RECOMMEND)}
      >
        <SearchMainTabCoachMark />
        추천
        {unReadRedDotItem && <span className={s.red_dot} />}
      </button>
    </div>
  );
};

export default SearchMainTab;
