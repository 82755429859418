export const Paths = {
  Search: '(/app)?/search',
  SearchMain: '/app/search/main',
  SearchResult: '/app/search/result',
  SearchHybridMain: '/app/search/hybrid/main',
  SearchHybridResult: '/app/search/hybrid/result',
  SearchRecentEdit: '/app/search/recent-edit',
  SearchOther: '(/app)?/search/*',

  Place: '(/app)?/place',
  PlaceMain: '/app/place/main',
  PlaceCategory: '/app/place/category',
  PlaceOther: '(/app)?/place/*',

  Proto: '/proto',
  ProtoListDrawer: '/proto/listdrawer',
  ProtoMap: '/proto/map',
  ProtoTNow: '/proto/tnow',
  ProtoImage: '/proto/image',
  ProtoAreaWords: '/proto/areaWords',
  ProtoCustomSelect: '/proto/customselect',
  ProtoPubImage: '/proto/pubimage',
  ProtoBanner: '/proto/banner',
  ProtoKeyword: '/proto/keyword',
  ProtoCuration: '/proto/curation',
  ProtoOther: '/proto/*',

  Dev: '/dev',
  DevDeepLink: '/dev/deeplink',
  DevVSMTNowTest: '/dev/vsm-tnow-test',
  DevVSMTest: '/dev/vsm-test',
  DevRedDot: '/dev/reddot',
  DevOther: '/dev/*-test',
  DevHybridBridge: '/dev/hybrid',
  DevAppInterface: '/dev/app-interface',
  DevListItem: '/dev/list-item',
};

export const SearchRoutes = [
  Paths.Search,
  Paths.SearchMain,
  Paths.SearchResult,
  Paths.SearchHybridMain,
  Paths.SearchHybridResult,
  Paths.SearchRecentEdit,
  Paths.SearchOther,
];
export const PlaceRoutes = [Paths.Place, Paths.PlaceMain, Paths.PlaceCategory, Paths.PlaceOther];
export const ProtoRoutes = [
  Paths.Proto,
  Paths.ProtoListDrawer,
  Paths.ProtoMap,
  Paths.ProtoTNow,
  Paths.ProtoImage,
  Paths.ProtoAreaWords,
  Paths.ProtoCustomSelect,
  Paths.ProtoPubImage,
  Paths.ProtoOther,
  Paths.ProtoBanner,
];
export const DevRoutes = [
  Paths.Dev,
  Paths.DevDeepLink,
  Paths.DevVSMTNowTest,
  Paths.DevVSMTest,
  Paths.DevOther,
  Paths.DevRedDot,
  Paths.DevHybridBridge,
  Paths.DevAppInterface,
  Paths.DevListItem,
];

export const SearchWebviewPages = [Paths.SearchMain, Paths.SearchResult];

export const SearchHybridPages = [Paths.SearchHybridMain, Paths.SearchHybridResult];

export const SearchMainPages = [Paths.SearchMain, Paths.SearchHybridMain];

export const SearchResultPages = [Paths.SearchResult, Paths.SearchHybridResult];

export const SearchServicePages = [...SearchWebviewPages, ...SearchHybridPages];
