import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import DiscoverySection from './DiscoverySection';
import DiscoveryRowPoiItem from './DiscoveryRowPoiItem';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';
import InView from 'react-intersection-observer';
import usePlaceHome from 'hooks/usePlaceHome';

const DiscoveryWeekend = () => {
  const {tplacehome, userInfo} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const poiList = useMemo(() => tplacehome.discoveryData.result.data.weekendList, [tplacehome]);

  return (
    <DiscoverySection mainTitle={'#주말에 가볼만한 곳'} subTitle={'내 취향을 담은'} pb={14}>
      <InView onChange={(isView) => isView && placehomeHook.sendEvent('view.weekend_outing_poi')}>
        <ul className={s.row_list}>
          {poiList.map((item, index) => {
            return (
              <li key={index} className={s.cell}>
                <DiscoveryRowPoiItem
                  data={item}
                  onClickDetail={() =>
                    placehomeHook.sendEvent('tap.weekend_outinge_poi', {
                      euk: userInfo.euk,
                      pkey: item.pkey,
                    })
                  }
                />
              </li>
            );
          })}
        </ul>
      </InView>
    </DiscoverySection>
  );
};

export default DiscoveryWeekend;
