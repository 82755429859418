import {Fragment, useCallback, useMemo} from 'react';
import InView from 'react-intersection-observer';
import {EDetailButtonType, TSearchPoi} from 'types/App';
import {parsePoiInfoToNavInfo} from 'utils/search';
import {getAppActionButton} from 'utils/tmapUtils';
import {AccommodationReservationButton} from 'components/AccommodationReservationButton';
import {PoiListItem} from 'components/PoiListItem';
import SearchNaverAd from 'components/search/SearchNaverAd';
import SearchResultSubPoiItem from 'components/search/SearchResultSubPoiItem';
import {generateTagComponentList} from 'hooks/usePoiMainContentTag';
import {useFocusDrawerList} from 'hooks/useFocusDrawerList';
import {useSearchImageList} from 'hooks/useSearchImageList';
import useMoveToTarget from 'hooks/useMoveToTarget';
import useLogger from 'hooks/useLogger';
import useAddress from 'hooks/useAddress';
import {NAVER_SA_POSITION_IDX} from 'constant/Ads';
import CatchTableReservationButton from 'components/CatchTableReservationButton';

import s from 'styles/components/search/SearchResultPoiList.module.scss';

type TProps = {
  list: TSearchPoi[];
  searchQuery: string;
};

const SearchResultPoiList = ({list, searchQuery}: TProps) => {
  const {activePoi, changeViewingItems} = useFocusDrawerList(list);
  const imageCounter = useSearchImageList();
  const {sendSearchResultItemClickLog, sendCouponClickLog, sendReservationClickLog} = useLogger();
  const {selectAddress} = useAddress();
  const {moveToDetail, moveToSelectDestinationAction, reqMode, reqType, extra} = useMoveToTarget();

  const saPosition = useMemo(() => {
    if (list.length < 2) {
      return -1;
    }
    if (list.length === 2) {
      return NAVER_SA_POSITION_IDX - 1;
    }

    return NAVER_SA_POSITION_IDX;
  }, [list]);

  const parseNavInfo = useCallback(
    (v: TSearchPoi) => {
      return parsePoiInfoToNavInfo({
        pkey: v.pkey || '',
        poiId: v.poiId || '',
        navSeq: `${v.navSeq || ''}`,
        navX: `${v.navX || ''}`,
        navY: `${v.navY || ''}`,
        centerX: `${v.centerX || ''}`,
        centerY: `${v.centerY || ''}`,
        rpFlag: v.rpFlag,
        poiName: v.listName || v.poiName,
        address: selectAddress({jibun: v.fullJibunAddr, road: v.fullRoadAddr}),
        tel: v.displayPhoneNumber,
        ...(v.stationInternalId && {
          stationId: v.stationInternalId,
          publicTransportType: v.stationType,
        }),
      });
    },
    [selectAddress]
  );

  const handleClickItem = useCallback(
    (e, v, index, tags) => {
      e.preventDefault();
      e.stopPropagation();

      sendSearchResultItemClickLog('list_tap.poi_detail', {
        index,
        tags,
      });
      moveToDetail(parseNavInfo(v));
    },
    [sendSearchResultItemClickLog, moveToDetail, parseNavInfo]
  );

  const handleClickRouteButton = useCallback(
    (e, v, index, tags, actionButtonType) => {
      e.preventDefault();
      e.stopPropagation();

      const actionId =
        actionButtonType === EDetailButtonType.CONFIRM
          ? 'list_tap.select_poi'
          : 'list_tap.poi_direction';

      sendSearchResultItemClickLog(actionId, {
        index,
        tags,
      });
      moveToSelectDestinationAction(parseNavInfo(v));
    },
    [sendSearchResultItemClickLog, moveToSelectDestinationAction, parseNavInfo]
  );

  return (
    <>
      {list.map((v, idx) => {
        const tags = generateTagComponentList(v);
        const actionButtonType = getAppActionButton({reqMode, reqType, extra});

        return (
          <Fragment key={idx}>
            <InView
              as="li"
              className={s.item}
              key={v.listId}
              data-type="poi"
              data-id={v.listId}
              threshold={0.5}
              onChange={(e) => changeViewingItems(e, idx)}
              data-focus={activePoi === v.listId}
            >
              <PoiListItem
                idx={idx}
                actionButtonType={actionButtonType}
                focus={activePoi === v.listId}
                poiData={{
                  ...v,
                  imageInfo: imageCounter.getListByViewport(v.imageInfo),
                }}
                onClickItem={(e) => handleClickItem(e, v, idx, tags)}
                onClickRouteButton={(e) =>
                  handleClickRouteButton(e, v, idx, tags, actionButtonType)
                }
              />
              <CatchTableReservationButton
                poiData={v}
                onClickReservation={() => {
                  sendReservationClickLog('tap.reserve', {index: idx, tags});
                }}
                onClickWaiting={() => {
                  sendReservationClickLog('tap.waiting', {index: idx, tags});
                }}
              />
              <AccommodationReservationButton
                poiData={v}
                onClick={() => {
                  sendSearchResultItemClickLog('list_tap.reservation_accommodation', {
                    index: idx,
                    tags,
                  });
                }}
              />
            </InView>
            {v.groupSubList.map((sub, subIdx) => {
              return (
                <InView
                  as="li"
                  className={s.sub_item}
                  key={sub.listId}
                  data-type="poi"
                  data-id={sub.listId}
                  threshold={0.5}
                  onChange={(e) => changeViewingItems(e, idx, subIdx)}
                  data-focus={activePoi === sub.listId}
                >
                  <SearchResultSubPoiItem
                    actionButtonType={actionButtonType}
                    idx={subIdx}
                    parentPoiData={v}
                    /* TODO fix */
                    poiData={sub as any}
                    onClick={() => {
                      sendSearchResultItemClickLog('list_tap.poigrp_detail', {
                        index: subIdx,
                        tags,
                      });
                    }}
                    onClickRouteButton={() => {
                      sendSearchResultItemClickLog('list_tap.poigrp_direction', {
                        index: subIdx,
                        tags,
                      });
                    }}
                    onClickCoupon={() => {
                      sendCouponClickLog({idx, tags});
                    }}
                  />
                </InView>
              );
            })}
            {idx === saPosition && (
              <SearchNaverAd className={s.keyword_banner_wrap} searchQuery={searchQuery} />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default SearchResultPoiList;
