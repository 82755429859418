import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {EViewMode} from 'pages/RankingPage';
import {TRankItem, TRankState} from 'ducks/rank/types';
import RankingPoiPhotoViewItem from './RankingPoiPhotoViewItem';
import RankingPoiItem from './RankingPoiItem';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {EDateFormat} from 'types/DateTime';
import {getMondayOfCurrentWeek, getTime} from 'utils/date';
import {IcoInfoFilled} from 'components/@tmds/icons/IcoInfoFilled';
import RankingTooltip from './RankingTooltip';
import {useSearchImageList} from 'hooks/useSearchImageList';
import ErrorReload from 'components/ErrorReload';
import RankingExpandPopup from './RankingExpandPopup';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import RankingFilterPopup from './RankingFilterPopup';
import RankingAdBanner from './RankingAdBanner';
import actions from 'ducks/actions';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import {EAddressMode} from 'types/Search';
import {NATIONAL_REGION} from 'components/search/SearchRankingDropDown';
import useMap from 'hooks/useMap';
import {useOnce} from 'hooks/useOnce';
import useLogger from 'hooks/useLogger';

import s from 'styles/components/ranking/RankingList.module.scss';

const BANNER_INJECT_INDEX = 5;
const SECOND_BANNER_INJECT_INDEX = 20;

type TProps = {
  mode: EViewMode;
  apiStatus: TRankState;
  onReload?: () => void;
  getList?: (pagingParam?: any) => any;
};

const RankingList = ({mode, apiStatus, onReload, getList}: TProps) => {
  const {activeFilters, isLandscape, currentAddressMap} = useAppSelector((state) => ({
    activeFilters: state.tRank.activeFilters,
    isLandscape: state.layout.appSize.isLandscape,
    currentAddressMap: state.tRank.currentAddressMap,
  }));
  const {loaded, error, displayList} = apiStatus;
  const {moveToDetail} = useMoveToTarget({});
  const imageCounter = useSearchImageList();
  const {camera, map} = useVSMInterfaceConsumer();
  const {moveToCenterWithOffset} = useMap();
  const {sendClickLog, sendRankingItemClickLog} = useLogger();
  const dispatch = useAppDispatch();
  const [showUpdateToolTip, setShowUpdateToolTip] = useState<boolean>(false);
  const refScroll = useRef<HTMLUListElement>(null);

  const clickedFilterLength = useMemo(
    () => Object.values(activeFilters).filter((v) => v.isActive).length,
    [activeFilters]
  );

  const handleClickItem = useCallback(
    (e, v: TRankItem) => {
      e.preventDefault();
      e.stopPropagation();

      sendRankingItemClickLog('tap.tmap_ranking_poi', {
        list_num: displayList.length,
        ranking_num: v.rank,
        pkey: v.pkey,
      });
      moveToDetail(v);
    },
    [moveToDetail, sendRankingItemClickLog]
  );

  const showBanner = useCallback(
    (index) => {
      const position = index + 1;
      const listLength = displayList.length;

      return listLength > BANNER_INJECT_INDEX && position === BANNER_INJECT_INDEX;
    },
    [displayList.length]
  );

  useEffect(() => {
    if (!isLandscape) {
      dispatch(actions.userInteraction.resetListInteraction());
      return;
    }

    map?.resize();
    refScroll.current
      ?.querySelector('[data-focus=true]')
      ?.scrollIntoView({behavior: 'smooth', block: 'center'});

    if (currentAddressMap[EAddressMode.CATE1]?.areaId === NATIONAL_REGION.areaId) {
      const item = displayList[0];

      moveToCenterWithOffset(
        {lon: item.wgs84CenterX, lat: item.wgs84CenterY},
        {x: -window.innerWidth / 4, y: window.innerHeight / 16}
      );
      window.setTimeout(() => {
        camera?.setZoom(4);
      }, 200);
      return;
    }
  }, [isLandscape]);

  useOnce(apiStatus.loaded, () => {
    sendClickLog('view.tmap_ranking_poi', {
      list_num: displayList.length,
    });
  });

  if (loaded && error) {
    return <ErrorReload onReload={onReload} />;
  }

  return (
    <ul className={s.wrap} ref={refScroll}>
      {displayList.map((v, i) => {
        const imageInfo = imageCounter.getTmapRankingListByViewPort(v.imageInfo);
        const isPhotoMode = mode === EViewMode.PHOTO;
        const ItemComponent = isPhotoMode ? RankingPoiPhotoViewItem : RankingPoiItem;

        return (
          <Fragment key={v.pkey}>
            <ItemComponent
              key={v.pkey}
              idx={i}
              poiData={{
                ...v,
                imageInfo,
              }}
              onClickItem={(e) => handleClickItem(e, v)}
            />

            {/* 내부에서는 5개 초과만 처리 */}
            {showBanner(i) && <RankingAdBanner isFirst={true} />}
            {i + 1 === SECOND_BANNER_INJECT_INDEX && <RankingAdBanner isFirst={false} />}
          </Fragment>
        );
      })}
      {/* 외부에서 5개 이하인 경우들 처리 */}
      {/* 필터 적용시 */}
      {clickedFilterLength > 0 && displayList.length <= BANNER_INJECT_INDEX && (
        <>
          {displayList.length === 0 && <RankingFilterPopup />}
          <RankingAdBanner isFirst={true} />
        </>
      )}
      {/* 필터 미적용시 */}
      {clickedFilterLength === 0 && displayList.length < SECOND_BANNER_INJECT_INDEX && (
        <>
          <RankingExpandPopup getList={getList} />
          {displayList.length <= BANNER_INJECT_INDEX && <RankingAdBanner isFirst={true} />}
        </>
      )}
      <div className={s.update_banner}>
        {getTime(getMondayOfCurrentWeek(Date.now()), EDateFormat.YYYYMMDD)} 업데이트
        <div
          className={s.icon}
          onClick={() => {
            setShowUpdateToolTip((prev) => !prev);
          }}
        >
          <IcoInfoFilled width={18} height={18} color="gray300" />
        </div>
        <div className={s.tooltip_wrap}>
          <RankingTooltip
            className={s.update_tooltip}
            showTooltip={showUpdateToolTip}
            onClickExit={() => setShowUpdateToolTip(false)}
            contents={
              <div>
                티맵 AI가 한 주간 이동 데이터를 <br />
                분석해 가장 인기 있는 장소를 순위 <br />
                별로 제공합니다.
              </div>
            }
          />
        </div>
      </div>
    </ul>
  );
};

export default RankingList;
