import {TCategoryLinkProperty, TCategoryPageProperty} from 'ducks/category/types';
import {EEVStationType} from 'types/App';

export type TRemoteExposeTime = {
  exposeTime: {
    start?: string;
    end?: string;
  };
};

export type TThemeMarkerConfig = TRemoteExposeTime & {
  code: string;
  description: string;
  icon: [string, string];
  marker: [string, string];
};

export enum ELandingType {
  // 인앱에서 브라우저 띄우기, openServiceByUrl
  IN_APP = 'inapp',
  // 외부브라우저 띄우기, openBrowser
  EXTERNAL = 'external',
  // ex) tmap://, openBrowser
  APP_SCHEME = 'appscheme',
}

export type TTNowPopupItem = TRemoteExposeTime & {
  type: ELandingType;
  imagePath: string;
  landingUrl: string;
};

export type TEvFilterListItem = {
  id: EEVStationType;
  label: string;
};

export type TRecommendKeywordItem = {
  type: 'search' | 'ad';
  keyword: string;
};

export enum ETPlaceCurationType {
  TYPE_1 = 'type_1',
  TYPE_2 = 'type_2',
  TYPE_3 = 'type_3',
  TYPE_4 = 'type_4',
}

export enum ECurationViewType {
  AROUND = 'around',
  DISCOVER = 'discover',
}

export type TTPlaceCurationItem = {
  poiName: string;
  category: string;
  landingUrl: string;
  landingType: ELandingType;
  thumbnailUrl: string;
};

export type TTPlaceCurationType1 = TRemoteExposeTime & {
  type: ETPlaceCurationType.TYPE_1;
  title: string;
  viewType: ECurationViewType;
  order: number;
  list: TTPlaceCurationItem[];
};

export type TTPlaceCurationType2 = TRemoteExposeTime & {
  type: ETPlaceCurationType.TYPE_2;
  title: string;
  desc: string;
  viewType: ECurationViewType;
  order: number;
  thumbnailUrl: string;
  list: Array<
    TTPlaceCurationItem & {
      address: string;
    }
  >;
};

export type TTPlaceCurationType3 = TRemoteExposeTime & {
  type: ETPlaceCurationType.TYPE_3;
  title: string;
  viewType: ECurationViewType;
  order: number;
  list: Array<{
    thumbnailUrl: string;
    landingUrl: string;
    landingType: ELandingType;
  }>;
};

export type TTPlaceCurationType4 = TRemoteExposeTime & {
  type: ETPlaceCurationType.TYPE_4;
  viewType: ECurationViewType;
  order: number;
  videoUrl: string;
};

export type TPlaceContent =
  | TTPlaceCurationType1
  | TTPlaceCurationType2
  | TTPlaceCurationType3
  | TTPlaceCurationType4;

export type TRemoteConfig = {
  lastUpdateTime?: number;
  rawRemoteData?: Record<string, any>;

  tnowCategoryLink: TCategoryLinkProperty[];
  searchMainCategoryLink: TCategoryLinkProperty[];
  searchResultCategoryLink: TCategoryLinkProperty[];

  customCategoryPage: TCategoryPageProperty[];
  themeMarker: TThemeMarkerConfig[];

  tnowPopupVersion?: number;
  tnowPopupList: TTNowPopupItem[];

  evFilterList: TEvFilterListItem[];

  searchRecommendKeyword: TRecommendKeywordItem[];
  tplaceContentsList: TPlaceContent[];
};
