import classNames from 'classnames';
import TmdsTag from 'components/@tmds/TmdsTag';
import ImageLoader from 'components/ImageLoader';
import {getImageUrl} from 'utils/url';
import {getDistance, setComma} from 'utils/formatter';
import {IcStarFill} from 'components/@tmds/icons/v1.2/IcStarFill';
import {IcLineBetween} from 'components/@tmds/icons/v1.2/IcLineBetween';
import {IcoStar} from 'components/@tmds/icons/IcoStar';

import {TPlaceItem} from 'ducks/place/types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {EBusinessHourStatus} from 'types/App';
import {IcoStarFilled} from 'components/@tmds/icons/IcoStarFilled';
import usePlaceHome from 'hooks/usePlaceHome';

import s from 'styles/components/tplacehome/TPlaceAroundMeItem.module.scss';
import {useAppSelector} from 'ducks/hooks';

type TProps = {
  item: TPlaceItem;
  itemIndex: number;
};

const IcoLineBetween = <IcLineBetween width={14} height={14} color="gray200" />;

const AroundMeItem = ({item, itemIndex}: TProps) => {
  const placehomeHook = usePlaceHome();
  const isFavorite = useMemo(() => placehomeHook.checkFavorite(item), [item, placehomeHook]);
  const wrapRef = useRef<HTMLDivElement>(null);
  const [isPointerActive, setIsPointerActive] = useState(false);
  const {layout, userInfo, tplacehome} = useAppSelector((state) => state);

  const isLocalFoodHotPlace = useMemo(
    () => item.tag.localFoodHotPlaceYn,
    [item.tag.localFoodHotPlaceYn]
  );

  const handleToggleFavorite = useCallback(() => {
    placehomeHook.sendEvent('tap.bookmark', {
      pkey: item.pkey,
      on: isFavorite,
    });
    placehomeHook.toggleFavorite(item);
  }, [isFavorite, item, placehomeHook]);

  const handleMoveToDetail = useCallback(
    (e) => {
      const logParam = {
        euk: userInfo.euk,
        list_num: tplacehome.aroundMeData.result.data.filteredList.length,
        list_seq: itemIndex + 1,
        pkey: item.pkey,
        local_popular: isLocalFoodHotPlace,
      };
      placehomeHook.sendEvent('tap.around_poi', logParam);
      placehomeHook.openPoiDetail(item);
    },
    [
      isLocalFoodHotPlace,
      item,
      itemIndex,
      placehomeHook,
      tplacehome.aroundMeData.result.data.filteredList.length,
      userInfo.euk,
    ]
  );

  const imageInfo = useMemo(() => {
    const displayCount = !layout.appSize.isLandscape && layout.windowSize.width > 600 ? 5 : 3;
    const imageList = item.imageInfo || [];
    const imageCount = Math.min(item.imageCount || 0, 1000);
    // display 갯수와 관계 없이 장소상세 기준으로 표시한다. https://tmobi.atlassian.net/browse/POIDETAIL-123
    const moreCount = Math.max(imageCount - 2, 0);
    const displayList = imageList.slice(0, displayCount);
    return {hasImage: !!displayList.length, displayList, moreCount, imageCount};
  }, [item.imageCount, item.imageInfo, layout.appSize.isLandscape, layout.windowSize.width]);

  const reviewInfo = useMemo(() => {
    const special = item.special;
    const count = special.reviewCount || 0;
    const score = special.avgStar || 0;
    const countText = setComma(count);
    const scoreText = setComma(score);
    const hasReview = !!(count || score);
    const noScore = !!(count && !score);
    const isView = !!(count && count >= 10);
    return {
      count,
      score,
      countText,
      scoreText,
      hasReview,
      noScore,
      isView,
    };
  }, [item.special]);

  const watingInfo = useMemo(() => {
    const catchTableWaitingResponse = item.special.catchTableWaitingResponse;
    const isWatingAvailable = !!catchTableWaitingResponse?.isAvailableOnlineWaiting;
    const watingCount = catchTableWaitingResponse?.unit?.count || 0;
    return {
      isWatingAvailable,
      watingCount,
    };
  }, [item.special.catchTableWaitingResponse]);

  const headingForScore = useMemo(() => item.headingForScore || 0, [item.headingForScore]);
  const inBusinessHour = useMemo(() => {
    const businessHourStatus = item.special.businessHourStatus;
    // TODAY_OFF = 'TODAY_OFF', //오늘휴무
    // BEFORE = 'BEFORE', //영업전
    // OPEN = 'OPEN', // 영업중
    // CLOSED = 'CLOSED', // 영업종료
    // BREAK_TIME = 'BREAK_TIME', // 브레이크 타임
    // CLOSING_SOON = 'CLOSING_SOON', // 영업종료임박
    switch (businessHourStatus) {
      case EBusinessHourStatus.TODAY_OFF:
        return {
          text: '오늘 휴무',
          color: '#F24724',
        };
      case EBusinessHourStatus.BEFORE:
        return {
          text: '영업 전',
          color: '#A3240B',
        };
      case EBusinessHourStatus.OPEN:
        return {
          text: '영업 중',
          color: '#51565C',
        };
      case EBusinessHourStatus.CLOSED:
        return {
          text: '영업 종료',
          color: '#51565C',
        };
      // @ts-ignore
      case 'BREAK_TIME':
        return {
          text: '브레이크타임',
          color: '#51565C',
        };
      // @ts-ignore
      case 'CLOSING_SOON':
        return {
          text: '곧 영업종료',
          color: '#51565C',
        };
      default:
        return false;
    }
  }, [item.special.businessHourStatus]);

  const tagInfoList = useMemo(() => {
    const tag = item.tag;
    const result: {label: string; color?: string; backgroundColor?: string}[] = [];
    if (tag.isPopular) {
      result.push({
        color: 'textSuccessBolder',
        backgroundColor: 'backgroundSuccessSecondary',
        label: '티맵인기',
      });
    }
    if (tag.isTVrestaurant) {
      result.push({
        color: 'tmobiTeal600',
        backgroundColor: 'tmobiTeal100',
        label: 'TV맛집',
      });
    }
    if (tag.isTmapReservation) {
      result.push({label: '예약'});
    }
    if (tag.isTmapWaiting) {
      result.push({label: '웨이팅'});
    }
    if (tag.isParking) {
      result.push({label: '주차'});
    }
    if (tag.isValetParking) {
      result.push({label: '발렛'});
    }
    return result;
  }, [item.tag]);

  // 즐겨찾기가 겹쳐서 touch-guide 활성 조건처리
  useEffect(() => {
    const on = (e) => {
      const targetEl = e.target as HTMLElement;
      if (targetEl.closest('[data-active-ignore]')) {
        return;
      }
      setIsPointerActive(true);
    };
    const off = () => setIsPointerActive(false);
    wrapRef.current?.addEventListener('mousedown', on);
    wrapRef.current?.addEventListener('mouseup', off);
    wrapRef.current?.addEventListener('touchstart', on);
    wrapRef.current?.addEventListener('touchend', off);
  }, []);

  return (
    <div
      className={classNames(s.wrap, {[s.active]: isPointerActive})}
      ref={wrapRef}
      data-id={item.listId}
    >
      {imageInfo.hasImage && (
        <div className={s.image_content_wrap} onClick={handleMoveToDetail}>
          {isLocalFoodHotPlace && <span className={s.tag}>로컬인기</span>}
          {!!imageInfo.moreCount && <span className={s.count}>+{imageInfo.moreCount}</span>}
          <ul
            className={s.image_wrap}
            style={
              {
                '--image-count': imageInfo.displayList.length,
              } as React.CSSProperties
            }
          >
            {imageInfo.displayList.map((v, i) => (
              // windowWidth. 겔럭시 폴트 접고 펼때 가운데(3번째) 이미지가 가려지는 현상으로 재렌더하기 위함
              <li key={v.path + i + item.listId + layout.windowSize.width} className={s.item}>
                <ImageLoader
                  src={getImageUrl(v)}
                  alt=""
                  toggleClass={s.fade_in}
                  className={classNames(s.image, {
                    [s.border_top_left]: i === 0,
                    [s.border_top_right]: i === imageInfo.displayList.length - 1,
                  })}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={s.content_wrap}>
        {/* TODO: 티맵 랭킹 아이콘과 비교 v1.2 사용하도록 가이드 */}
        <div className={s.icon_star} onClick={handleToggleFavorite} data-active-ignore>
          {isFavorite ? (
            <IcoStarFilled width={24} height={24} color="yellow500" />
          ) : (
            <IcoStar width={24} height={24} color="gray300" />
          )}
        </div>
        <div onClick={handleMoveToDetail}>
          {/* poiName, 업종 */}
          <div className={s.title_wrap}>
            <span className={s.title}>{item.poiName}</span>
            <span className={s.category}>{item.svcCategoryName}</span>
          </div>

          {/* 리뷰, 웨이팅 */}
          {(reviewInfo.isView || watingInfo.isWatingAvailable) && (
            <div className={s.detail_wrap}>
              {/* 리뷰 */}
              {reviewInfo.isView && (
                <div className={s.review_wrap}>
                  {reviewInfo.noScore ? (
                    <span className={s.count}>리뷰 {reviewInfo.count}</span>
                  ) : (
                    <>
                      <span className={s.star_fill_icon}>
                        <IcStarFill color="tmobiTeal400" width={16} height={16} />
                      </span>
                      <span className={s.count}>
                        <em>{reviewInfo.score.toFixed(1)}</em>({reviewInfo.count})
                      </span>
                    </>
                  )}
                </div>
              )}
              {/* 웨이팅 */}
              {watingInfo.isWatingAvailable && (
                <>
                  {reviewInfo.isView && IcoLineBetween}
                  <span className={s.waiting_wrap}>현재 웨이팅 {watingInfo.watingCount}팀</span>
                </>
              )}
            </div>
          )}

          {/* 영업상태, 거리, 몇대가는중 */}
          <div className={s.etc_wrap}>
            {inBusinessHour && (
              <span style={{color: inBusinessHour.color}}>{inBusinessHour.text}</span>
            )}
            {item.distance > 0 && (
              <>
                {inBusinessHour && IcoLineBetween}
                <span>현위치에서 {getDistance(item.distance)}</span>
              </>
            )}
            {headingForScore > 0 && (
              <>
                {(inBusinessHour || item.distance > 0) && IcoLineBetween}
                <span className={s.tnow_count}>{item.headingForScore}대 가는 중</span>
              </>
            )}
          </div>

          {/* 태그 */}
          {tagInfoList.length > 0 && (
            <div className={s.tag_wrap}>
              {tagInfoList.map((tagItem) => (
                <TmdsTag
                  key={tagItem.label}
                  label={tagItem.label}
                  filled={true}
                  fontSize={11}
                  color={tagItem.color || 'textBasicDescription'}
                  backgroundColor={tagItem.backgroundColor || 'gray50'}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AroundMeItem;
