import {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import {EAppPackage} from 'types/App';
import {TNaverBannerAdSlotInfo, TNaverBannerConfig} from 'types/Ads';

import {useAppSelector} from 'ducks/hooks';

import {ENaverDaUnitId} from 'constant/Ads';

import ua from 'utils/uaParser';
import {devLog} from 'utils/dev';

import s from 'styles/modules/NaverBanner.module.scss';

type TProps = {
  onStart?: (adSlotInfo?: TNaverBannerAdSlotInfo) => void;
  onShow?: VoidFunction;
  onLoad?: VoidFunction;
  onError?: (error?: any) => void;
  onClickAd?: (url?: string) => void;
  adUnitId?: ENaverDaUnitId;
};
const TEMP_ADID = uuidv4();

// https://github.com/naver/nam-sdk-web
const NaverBanner = ({onStart, onShow, onLoad, onError, onClickAd, adUnitId}: TProps) => {
  const [targetId] = useState<string>(`naverBanner_${uuidv4()}`);

  const {adId, deviceServiceVendorId} = useAppSelector((state) => ({
    adId: state.userInfo.adId || TEMP_ADID,
    deviceServiceVendorId: state.userInfo.deviceServiceVendorId,
  }));

  useEffect(() => {
    if (adId) {
      const globalConfig: TNaverBannerConfig = {
        appName: ua.isIos ? EAppPackage.IOS : EAppPackage.AOS,
        appVersion: ua.tmapAppVersion,
      };

      const adSlotInfo: TNaverBannerAdSlotInfo = {
        adUnitId: adUnitId as ENaverDaUnitId,
        adSlotElementId: targetId,
        ai: adId,
        iv: deviceServiceVendorId || '',
        uct: 'KR',
      };

      onStart?.(adSlotInfo);

      window['gladsdk']?.cmd.push(function () {
        /*
        INFO: https://naver.github.io/glad-sdk-guide/ko/web_new/advanced/trouble_shooting/#gladsdk-스코프-체이닝으로-인한-문제
        상위 스코프에서 (ex. cmd.push 상단) 에서 지역변수로 gladsdk 할당시 gladsdk 로드 전 window에 기본 할당된 애를 참조하게 되어서 defindAdSlot같은 함수가 실행 안됨
        Asum 처럼 별도로 sdk 로드 체크 안하고 cmd 안에 넣어놓기만 하면 sdk load 완료된 후 자동 실행 됨
        */
        const gladsdk = window['gladsdk'];

        // https://tmobi.atlassian.net/browse/POIDETAIL-111
        // 여러개의 광고가 한 페이지에 노출 될 경우, 이전 ad slot 을 삭제하는 이슈가 있어서,
        // 해당 부분을 주석처리

        // 기존 등록된것 삭제
        // gladsdk.destroyAdSlots();
        // gladsdk.removeAllEventListener();

        // 신규 광고 등록
        gladsdk.setGlobalConfig(globalConfig);
        gladsdk.defineAdSlot(adSlotInfo);

        // 이벤트 등록
        gladsdk.addEventListener(gladsdk.event.AD_LOADED, (adInfo) => {
          devLog('naver ad', gladsdk.event.AD_LOADED, adInfo);
          onShow?.();
        });
        gladsdk.addEventListener(gladsdk.event.AD_CLICKED, (adInfo) => {
          devLog('naver ad', gladsdk.event.AD_CLICKED, adInfo);
        });
        gladsdk.addEventListener(gladsdk.event.AD_IMPRESSED, (adInfo) => {
          devLog('naver ad', gladsdk.event.AD_IMPRESSED, adInfo);
        });
        gladsdk.addEventListener(gladsdk.event.ERROR, (adInfo, error) => {
          devLog('naver ad', gladsdk.event.ERROR, adInfo, error);
          onError?.(error);
        });

        // 자체 클릭을 믹고 url만 얻어옴
        const adSlot = gladsdk.findAdSlot(targetId);

        adSlot.setNativeClickHandler((curl, furl) => {
          // curl : click url(string), furl: fallback url(string)
          devLog('naver ad', 'native click', curl, furl);

          if (curl) {
            onClickAd?.(curl);
          } else {
            onError?.('curl is empty');
          }
        });

        // 화면 노출
        gladsdk.displayAd(targetId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adId]);

  return <div id={targetId} className={s.banner_wrap} />;
};

export default NaverBanner;
