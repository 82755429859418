import {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {TPoiDataJson} from '@lcc/tmap-inapp';
import {ECalloutActionId} from 'constant/Log';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchUserData} from 'ducks/userInfo/slice';
import useThrottle from 'hooks/useThrottle';
import useLogger from 'hooks/useLogger';
import useFavorite from 'hooks/useFavorite';
import useAppScheme from 'hooks/useAppScheme';
import useUserPublicTransData from 'hooks/useUserPublicTransData';

import {ReactComponent as IconFavorite} from 'resource/images/@tmds_basic/ico_star.svg';
import {ReactComponent as IconFavoriteOn} from 'resource/images/@tmds_solid/ico_star_solid.svg';

import s from 'styles/components/FavoriteButton.module.scss';

type TProps = {
  isDisabled: boolean;
  className: string;
  poiJson: TPoiDataJson | null;
};

const FavoriteButton = ({isDisabled, poiJson, className}: TProps) => {
  const dispatch = useAppDispatch();
  const ableToRun = useThrottle();
  const {sendClickLogWithMapView} = useLogger();

  const {calloutInfo, isUserDataLoaded} = useAppSelector((state) => ({
    calloutInfo: state.userInteraction.calloutInfo || state.userInteraction.pickOnMapCalloutInfo,
    isUserDataLoaded: state.userInfo.personalPlace.loaded,
  }));

  const [showFavorite, setShowFavorite] = useState(false);
  const {init: fetchUserPublicTransData} = useUserPublicTransData();
  const {checkIsFavorite} = useFavorite();
  const inApp = useAppScheme();

  const isPublicTransPoi = calloutInfo?.stationSktId && calloutInfo?.publicTransportType;

  const handleClick = useCallback(() => {
    if (!ableToRun() || !calloutInfo || !poiJson) {
      return;
    }

    inApp
      .toggleFavorite({
        ...poiJson,
        ...(isPublicTransPoi && {
          stationId: calloutInfo.stationSktId,
          publicTransportType: calloutInfo.publicTransportType,
        }),
      })
      .then((isOn) => {
        dispatch(fetchUserData());

        sendClickLogWithMapView(
          isOn ? ECalloutActionId.ADD_FAVORITE : ECalloutActionId.DELETE_FAVORITE,
          {pkey: poiJson.pkey}
        );
      });
  }, [ableToRun, calloutInfo, poiJson, dispatch, sendClickLogWithMapView, isPublicTransPoi, inApp]);

  useEffect(() => {
    // 상세, 다른 탭에서 좋아요 누르고 왔을 때
    if (poiJson) {
      const isFavorite = checkIsFavorite(poiJson);
      setShowFavorite(!!isFavorite);
    }
  }, [poiJson, checkIsFavorite]);

  useEffect(() => {
    if (isUserDataLoaded && isPublicTransPoi) {
      fetchUserPublicTransData();
    }
  }, [isUserDataLoaded]);

  return (
    <button
      data-on={showFavorite}
      disabled={isDisabled}
      className={classNames(s.button, className)}
      onClick={handleClick}
    >
      {showFavorite ? <IconFavoriteOn /> : <IconFavorite />}
    </button>
  );
};

export default FavoriteButton;
