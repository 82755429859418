// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcoMapPinFilledSvg} from 'resource/@tmdsicon/ico_map_pin_f.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcoMapPinFilled = (props: TProps) => {
    return <TmdsIcon {...props} Icon={<IcoMapPinFilledSvg {...props} />} />;
};

export {IcoMapPinFilled};
