import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {TPoiDataJson} from '@lcc/tmap-inapp';
import {useAppSelector} from 'ducks/hooks';
import {ERPFlag, ESkeletonType} from 'types/App';
import {TBusArrivalInfo, TSubwayArrivalInfo} from 'types/PublicTrans';
import {NO_ADDRESS} from 'constant/Text';
import {ECalloutActionId} from 'constant/Log';
import useReverseGeocoding from 'hooks/useReverseGeocoding';
import useThrottle from 'hooks/useThrottle';
import useLogger from 'hooks/useLogger';
import {addressInfoFromReverseGeoData} from 'utils/map';
import {parsePoiInfoToNavInfo} from 'utils/search';
import usePoiBasicInfo from 'hooks/usePoiBasicInfo';
import useAddress from 'hooks/useAddress';
import useAppScheme from 'hooks/useAppScheme';
import useMoveToTarget from 'hooks/useMoveToTarget';
import usePublicTransArrivalInfo from 'hooks/usePublicTransArrivalInfo';
import {TCalloutInfo} from 'ducks/userInteraction/types';
import Skeleton from 'components/Skeleton';
import CalloutInfoSubway from 'components/CalloutInfoSubway';
import CalloutInfoBusStation from 'components/CalloutInfoBusStation';
import CurrentPositionButton from 'components/CurrentPositionButton';
import PoiCalloutPopupButtons from 'components/PoiCalloutPopupButtons';
import {IcoArrowRightBold} from 'components/@tmds/icons/IcoArrowRightBold';
import {IcoRefresh} from 'components/@tmds/icons/IcoRefresh';
import TmdsTag from 'components/@tmds/TmdsTag';

import s from 'styles/components/PoiCalloutPopup.module.scss';

type TProps = {calloutInfo: TCalloutInfo};

const TIME_TABLE_TAG_STYLE = {
  label: '시간표',
  backgroundColor: 'gray50',
  color: 'gray600',
};

const REAL_TIME_TAG_STYLE = {
  label: '실시간',
  backgroundColor: 'tmobiBlue100',
  color: 'tmobiBlue600',
};

export const PoiCalloutPopup = ({calloutInfo}: TProps) => {
  const ableToRun = useThrottle();
  const {sendClickLogWithMapView} = useLogger();
  const {isUseJibun} = useAddress();
  const {moveToDetail, reqMode} = useMoveToTarget();
  const inApp = useAppScheme();

  const {isLandscape} = useAppSelector((state) => ({
    isLandscape: state.layout.appSize.isLandscape,
  }));

  const geoState = useReverseGeocoding(calloutInfo);
  const poiState = usePoiBasicInfo({
    pkey: calloutInfo?.pkey || undefined,
    poiId: calloutInfo?.poiId || undefined,
  });

  const {state: publicTransState, fetchApi: fetchTransApi} = usePublicTransArrivalInfo(calloutInfo);
  const isSubway = calloutInfo.publicTransportType === 'subway';
  const isBusStop = calloutInfo.publicTransportType === 'busstop';
  const isRealTime = !!(publicTransState.data as TSubwayArrivalInfo)?.isRealTime;

  const isPoiInfo = useMemo(
    () =>
      !!(
        calloutInfo?.stationSktId ||
        calloutInfo?.pkey ||
        (calloutInfo?.poiId && !`${calloutInfo?.poiId}`.startsWith('99'))
      ),
    [calloutInfo]
  );

  // poi 일경우 poi 좌표를 사용하므로 poi load되면 enable. 나머지는 reverseGeo 완료되면 enable.
  const buttonDisable = useMemo(() => {
    if (isPoiInfo) {
      // 대중교통일 경우 데이터가 없어도 버튼 노출
      if (calloutInfo?.stationSktId) {
        return !!poiState.loading;
      }
      return !!(poiState.error || poiState.loading);
    }

    return !!(geoState.error || geoState.loading);
  }, [isPoiInfo, geoState, poiState, calloutInfo]);

  // 주소 정보는 reverseGeo 를 사용
  const address = useMemo(() => {
    const {data: poiData, loaded: poiLoaded} = poiState;
    const {data: geoData, loaded: geoLoaded} = geoState;

    if (geoLoaded && geoData) {
      const addressInfoFromGeo = addressInfoFromReverseGeoData(geoData, isUseJibun);

      if (!isPoiInfo) {
        return addressInfoFromGeo;
      }

      if (poiLoaded) {
        const priority = isUseJibun
          ? poiData?.fullJibunAddr || addressInfoFromGeo.jibun
          : poiData?.fullRoadAddr || addressInfoFromGeo.road;
        const sub = isUseJibun
          ? poiData?.fullRoadAddr || addressInfoFromGeo.road
          : poiData?.fullJibunAddr || addressInfoFromGeo.jibun;
        const absoluteExist = priority || sub;

        return {
          jibun: addressInfoFromGeo.jibun,
          road: addressInfoFromGeo.road,
          priority,
          sub,
          absoluteExist,
        };
      }
    }

    return {jibun: '', road: '', priority: '', sub: '', absoluteExist: undefined};
  }, [geoState, poiState, isPoiInfo, isUseJibun]);

  // 좌표는 poi는 poiInfo. 나머지는 reverseGeo.
  const skBesselCoordinate = useMemo(() => {
    const {data: poiData} = poiState;
    const {data: geoData} = geoState;

    const reverseResult =
      (isUseJibun ? geoData?.jibunAddrCoordination : geoData?.roadAddrCoordination) ||
      geoData?.jibunAddrCoordination;

    if (isPoiInfo) {
      return {
        centerX:
          poiData?.centerX ||
          calloutInfo?.centerX ||
          (isBusStop ? geoData?.centerX : reverseResult?.centerX),
        centerY:
          poiData?.centerY ||
          calloutInfo?.centerY ||
          (isBusStop ? geoData?.centerY : reverseResult?.centerY),
        navX:
          poiData?.navX ||
          calloutInfo?.navX ||
          (isBusStop ? geoData?.centerX : reverseResult?.navX) ||
          geoData?.roadAddrCoordination.navX,
        navY:
          poiData?.navY ||
          calloutInfo?.navY ||
          (isBusStop ? geoData?.centerY : reverseResult?.navY) ||
          geoData?.roadAddrCoordination.navY,
      };
    } else {
      return {
        centerX: geoData?.centerX,
        centerY: geoData?.centerY,
        navX: geoData?.centerX,
        navY: geoData?.centerY,
      };
    }
  }, [isUseJibun, isPoiInfo, geoState, poiState, calloutInfo, isBusStop]);

  // customName이 최우선
  // customName이 없을때 Poi는 poi Info
  // customName이 없을때 좌표는 geo address
  const {title, desc} = useMemo(() => {
    const {data: poiData, loaded: poiLoaded} = poiState;
    const {loaded: geoLoaded} = geoState;

    if (geoLoaded) {
      const addressTitle = calloutInfo?.customName || address.priority || address.sub;
      let addressDesc = calloutInfo?.customName
        ? address.absoluteExist
        : address.priority
        ? address.sub
        : '';

      if (addressTitle === addressDesc) {
        const candidate = [address.absoluteExist, address.priority, address.sub];

        addressDesc = candidate.find((c) => c !== addressTitle);
      }

      if (!isPoiInfo) {
        return {
          title: calloutInfo?.customName || (geoState.error ? NO_ADDRESS : addressTitle),
          desc: addressDesc,
        };
      }

      if (poiLoaded) {
        const poiAddressDesc = poiData
          ? isUseJibun
            ? poiData.fullJibunAddr
            : poiData.fullRoadAddr || poiData.fullJibunAddr
          : addressDesc;

        return {
          title:
            calloutInfo?.customName ||
            poiData?.poiNameSvc ||
            calloutInfo?.publicTransportName ||
            addressTitle,
          desc: poiAddressDesc,
        };
      }
    }

    if (isPoiInfo && poiLoaded) {
      return {
        title:
          calloutInfo?.customName || poiData?.poiNameSvc || calloutInfo?.publicTransportName || '',
        desc: '',
      };
    }

    return {
      title: calloutInfo?.customName || calloutInfo?.publicTransportName,
      desc: '',
    };
  }, [
    poiState,
    geoState,
    isPoiInfo,
    calloutInfo?.customName,
    calloutInfo?.publicTransportName,
    address.priority,
    address.sub,
    address.absoluteExist,
    isUseJibun,
  ]);

  const poiJson: TPoiDataJson | null = useMemo(() => {
    if (!calloutInfo && (isPoiInfo ? !poiState.loaded : !geoState.loaded)) {
      return null;
    }
    return parsePoiInfoToNavInfo({
      pkey: `${isPoiInfo ? calloutInfo?.pkey ?? '' : ''}`,
      poiId: `${isPoiInfo ? calloutInfo?.poiId ?? '' : ''}`,
      navSeq: `${calloutInfo?.navSeq ?? ''}`,
      poiName: `${title || ''}`,
      navX: `${skBesselCoordinate.navX ?? ''}`,
      navY: `${skBesselCoordinate.navY ?? ''}`,
      centerX: `${skBesselCoordinate.centerX ?? ''}`,
      centerY: `${skBesselCoordinate.centerY ?? ''}`,
      address: address.priority || address.jibun,
      tel: '',
      rpFlag: calloutInfo?.rpFlag !== undefined ? Number(calloutInfo?.rpFlag) : ERPFlag.N_G000,
      stationId: calloutInfo?.stationSktId,
      publicTransportType: calloutInfo?.publicTransportType,
    });
  }, [
    isPoiInfo,
    calloutInfo,
    title,
    poiState.loaded,
    geoState.loaded,
    skBesselCoordinate,
    address,
  ]);

  const loadedAddress = useMemo(() => {
    return isPoiInfo ? poiState.loaded : geoState.loaded;
  }, [geoState.loaded, isPoiInfo, poiState.loaded]);

  const skeletonState = useMemo(
    () => ({
      loading:
        poiState.loading ||
        geoState.loading ||
        (calloutInfo.publicTransportType ? publicTransState.loading : false),
      loaded:
        poiState.loaded &&
        geoState.loaded &&
        (calloutInfo.publicTransportType ? publicTransState.loaded : true),
      data: {},
    }),
    [poiState, geoState, calloutInfo, publicTransState]
  );

  const moveToPoiDetail = useCallback(() => {
    if (poiJson && loadedAddress && !buttonDisable) {
      const data =
        !isPoiInfo && geoState.data
          ? {
              ...poiJson,
              pkey: isUseJibun ? geoState.data.jibunPkey : geoState.data.roadPkey,
            }
          : poiJson;

      sendClickLogWithMapView(ECalloutActionId.INFO, {pkey: data.pkey});

      moveToDetail(data);
    }
  }, [
    poiJson,
    loadedAddress,
    buttonDisable,
    isPoiInfo,
    geoState.data,
    isUseJibun,
    sendClickLogWithMapView,
    moveToDetail,
  ]);

  const handleClickRefresh = useCallback(
    (e) => {
      e.stopPropagation();

      fetchTransApi(calloutInfo);
    },
    [fetchTransApi, calloutInfo]
  );

  const handleClickTitle = useCallback(() => {
    if (!ableToRun() || !poiJson) {
      return;
    }

    if (isBusStop) {
      inApp.openBusStationDetail({
        name: poiJson.poiName,
        poiId: poiJson.poiId,
        centerX: poiJson.centerX,
        centerY: poiJson.centerY,
        stationId: calloutInfo.stationSktId,
        reqMode,
      });

      return;
    }

    moveToPoiDetail();
  }, [ableToRun, isBusStop, poiJson, moveToPoiDetail, calloutInfo, reqMode, inApp]);

  return (
    <div className={s.callout_popup}>
      {!isLandscape && <CurrentPositionButton className={s.current_button} />}

      <Skeleton type={ESkeletonType.CALLOUT_POPUP} apiStatus={skeletonState}>
        <div
          className={classNames(s.poi_info, {
            [s.is_subway]: isSubway,
            [s.is_busstop]: isBusStop,
          })}
        >
          <div className={s.title} onClick={handleClickTitle}>
            <div className={s.title_wrap}>
              <span className={s.text}>{(loadedAddress && title) || NO_ADDRESS}</span>
              {loadedAddress && title && (
                <IcoArrowRightBold
                  className={s.icon_right}
                  width={20}
                  height={20}
                  color="gray900"
                />
              )}
            </div>
            {isSubway && (
              <div className={s.refresh_wrap}>
                <TmdsTag
                  {...(isRealTime ? REAL_TIME_TAG_STYLE : TIME_TABLE_TAG_STYLE)}
                  filled={true}
                  type="bold"
                  fontSize={12}
                  className={s.tmds_tag}
                />
                <button
                  className={classNames(s.refresh_button, {
                    [s.rotate]: publicTransState.loading,
                  })}
                  onClick={handleClickRefresh}
                >
                  <IcoRefresh width={20} height={20} />
                </button>
              </div>
            )}
          </div>

          {isSubway ? (
            <CalloutInfoSubway
              {...publicTransState}
              data={publicTransState.data as TSubwayArrivalInfo}
            />
          ) : isBusStop ? (
            <CalloutInfoBusStation
              data={publicTransState.data as TBusArrivalInfo}
              lonLat={calloutInfo}
            />
          ) : (
            <div className={s.address}>{desc}</div>
          )}
        </div>
        <PoiCalloutPopupButtons poiJson={poiJson} buttonDisable={buttonDisable} />
      </Skeleton>
    </div>
  );
};
