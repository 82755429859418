import {ERecentItemType} from 'types/Search';

import {ReactComponent as IcMapRouteRight2} from 'resource/images/ic_map_route_right_2.svg';
import {IcMapRouteRight1Fill} from '../@tmds/icons/v1.2/IcMapRouteRight1Fill';
import {IcSearchBold} from '../@tmds/icons/v1.2/IcSearchBold';
import {IcHomeFill} from '../@tmds/icons/v1.2/IcHomeFill';
import {IcCompanyFill} from '../@tmds/icons/v1.2/IcCompanyFill';
import {IcStarFill} from '../@tmds/icons/v1.2/IcStarFill';
import {IcBusstopFill} from '../@tmds/icons/v1.2/IcBusstopFill';
import {IcMobilityMetroFill} from '../@tmds/icons/v1.2/IcMobilityMetroFill';

type TProps = {
  srcType: ERecentItemType;
  className?: string;
};

const IMAGE_SRC_MAP: Record<ERecentItemType, JSX.Element> = {
  [ERecentItemType.POI]: <IcMapRouteRight1Fill width={20} height={20} color={'gray400'} />,
  [ERecentItemType.QUERY]: <IcSearchBold width={20} height={20} color={'gray400'} />,
  [ERecentItemType.PUBLIC_POI]: <IcMapRouteRight2 />,
  [ERecentItemType.HOME]: <IcHomeFill width={20} height={20} color={'gray400'} />,
  [ERecentItemType.OFFICE]: <IcCompanyFill width={20} height={20} color={'gray400'} />,
  [ERecentItemType.FAVORITE]: <IcStarFill width={20} height={20} color={'yellow500'} />,
  [ERecentItemType.FAVORITE_STATION]: <IcStarFill width={20} height={20} color={'tmobiBlue300'} />,
  [ERecentItemType.FAVORITE_SUBWAY]: <IcStarFill width={20} height={20} color={'tmobiBlue300'} />,
  [ERecentItemType.BUS_STATION]: <IcBusstopFill width={20} height={20} color={'gray400'} />,
  [ERecentItemType.SUBWAY]: <IcMobilityMetroFill width={20} height={20} color={'gray400'} />,
};

const SearchListIcon = ({srcType, className}: TProps): JSX.Element => {
  const IconElement = IMAGE_SRC_MAP[srcType];

  return (
    <i className={className} data-type={srcType}>
      {IconElement}
    </i>
  );
};

export default SearchListIcon;
