import classNames from 'classnames';
import {useAppSelector} from 'ducks/hooks';
import React, {PropsWithChildren, useCallback, useEffect, useRef, useState} from 'react';
import {ReactComponent as IconToTop} from 'resource/images/@tmds_basic/ico_arrow_tale_up.svg';
import s from 'styles/components/tplacehome/TPlaceContentWrap.module.scss';
import {EListMode} from 'types/ListDrawer';
import {debounce} from 'utils/lodash';

type Tprops = {
  onContentScroll?: (e: React.UIEvent) => void;
  contentScrollTop?: number;
  contentScrollTopKey?: number;
  hideBtnTop?: boolean;
};

const ContentWrap = ({
  children,
  onContentScroll,
  contentScrollTop = 0,
  contentScrollTopKey = 0,
  hideBtnTop = false,
}: PropsWithChildren<Tprops>) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const {tplacehome, layout} = useAppSelector((state) => state);

  const [padHeight, setPadHeight] = useState(472);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calcPadHeight = useCallback(
    debounce(() => {
      const padTargetHeight = 128; // pad가 보여져야 할 값
      const windowHeight = window.innerHeight;
      // 드로워의 y값 (-좌표)
      const drawerY = (() => {
        const el = wrapRef.current?.closest('[class*=DrawerContainer_list_wrap]');
        if (!el) {
          return -450;
        }
        const style = window.getComputedStyle(el);
        const matrix = new WebKitCSSMatrix(style.transform);
        return matrix.m42;
        // console.log('translateX: ', matrix.m42);
      })();
      // 드로워에서 콘텐츠가 시작하는 top
      const contentOffsetTop = wrapRef.current?.offsetTop || 76;
      const contentOriginTop = windowHeight - Math.abs(drawerY) + contentOffsetTop;

      const resultHeight = contentOriginTop + padTargetHeight;
      setPadHeight(resultHeight);
    }, 300),
    []
  );

  useEffect(
    () => calcPadHeight(),
    [layout.windowSize.width, layout.windowSize.height, layout.appSize.isLandscape, calcPadHeight]
  );

  const centerFlag = useRef(false);
  useEffect(() => {
    if (!centerFlag.current && tplacehome.currentDrawerListMode === EListMode.CENTER) {
      calcPadHeight();
      centerFlag.current = true;
    }
  }, [calcPadHeight, tplacehome.currentDrawerListMode]);

  const moveScroll = useCallback((value: number) => {
    if (wrapRef.current) {
      wrapRef.current.scrollTop = value;
    }
  }, []);

  useEffect(
    () => moveScroll(contentScrollTop),
    [contentScrollTop, contentScrollTopKey, moveScroll]
  );

  return (
    <>
      <div
        className={s.content_scroll_wrap}
        ref={wrapRef}
        onScroll={(e) => onContentScroll && onContentScroll(e)}
      >
        {children}
        <div className={s.pad} style={{height: padHeight}}>
          {!hideBtnTop && (
            <div className={classNames(s.btn_to_top, s.show)} onClick={() => moveScroll(0)}>
              <IconToTop />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentWrap;
