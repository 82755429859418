import classNames from 'classnames';
import TMapSender, {ERedDotItemGroup} from '@lcc/tmap-inapp';
import RouteAddPopup from 'components/RouteAddPopup';
import {SearchBar} from 'components/legacySearch/SearchBar';
import SearchAddress from 'components/search/SearchAddress';
import SearchPickOnMap from 'components/search/SearchPickOnMap';
import SearchRecentListEdit from 'components/search/SearchRecentListEdit';
import SearchTab from 'components/search/SearchTab';
import {useOnce} from 'hooks/useOnce';
import {useSearchMainTab} from 'hooks/useSearchMainTab';
import {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {VSMInterfaceProvider} from 'context/VSMInterfaceContext';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {ESearchTabs, ETab} from 'types/Search';
import HybridBridge from 'utils/searchBar';
import {useAppLayout} from 'hooks/useAppLayout';
import {EAppRequestMode, EDetailButtonType} from 'types/App';
import {useSearchPageVisible} from 'hooks/useSearchPageVisible';
import useNaverKeywordAd from 'hooks/useNaverKeywordAd';
import {init as initLog, sendExpose, sendSearchClickLog} from 'utils/logManager';
import {
  EPageType,
  TLA_SEARCH_MAIN_ADDRESS,
  TLA_SEARCH_MAIN_FESTIVAL,
  TLA_SEARCH_MAIN_MAP,
  TLA_SEARCH_MAIN_RECENT,
  TLA_SEARCH_MAIN_RECENT_EDIT,
  TLA_SEARCH_MAIN_RECOMMEND,
} from 'constant/Log';
import {useParamLog} from 'hooks/useParamLog';
import {getAppActionButton, getCurrentPackageVersion, isOver10_6Version} from 'utils/tmapUtils';
import {getFirstParam} from 'utils/search';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import SearchEventTab from 'components/search/SearchEventTab';
import SearchMainRecommend from '../components/search/SearchMainRecommend';
import SearchMainTab from '../components/search/SearchMainTab';
import SearchFestival from '../components/search/SearchFestival';
import SearchMainRecent from '../components/search/SearchMainRecent';
import {IcArrowRight} from '../components/@tmds/icons/v1.2/IcArrowRight';
import useRedDot from '../hooks/useRedDot';
import useCoachMark from '../hooks/useCoachMark';

import s from 'styles/pages/SearchMainPage.module.scss';

type TProps = {
  hide?: boolean;
};

const PageIdMap = {
  [ESearchTabs.RECENT]: TLA_SEARCH_MAIN_RECENT,
  [ESearchTabs.RECENT_EDIT]: TLA_SEARCH_MAIN_RECENT_EDIT,
  [ESearchTabs.ADDRESS]: TLA_SEARCH_MAIN_ADDRESS,
  [ESearchTabs.MAP]: TLA_SEARCH_MAIN_MAP,
  [ESearchTabs.RECOMMEND]: TLA_SEARCH_MAIN_RECOMMEND,
  [ESearchTabs.FESTIVAL]: TLA_SEARCH_MAIN_FESTIVAL,
};

export const SearchMainPage = ({hide}: TProps) => {
  const dispatch = useAppDispatch();
  const {isHybrid, userInfo} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    userInfo: state.userInfo,
  }));

  const {isCoachMark} = useCoachMark();
  const {redDotList, getRedDotItem, updateRedDotList} = useRedDot({
    itemGroup: ERedDotItemGroup.SEARCH,
  });
  const {showMain, showResult} = useSearchPageVisible();
  const {referrer} = useParamLog();
  const {originQueries} = useParseQueryLocation();
  const {reqMode, extra, reqType, moveToSearch} = useMoveToTarget({
    from: EFromType.SEARCH_MAIN,
  });
  const actionButtonType = getAppActionButton({reqMode, reqType, extra});
  const isConfirmMode = actionButtonType === EDetailButtonType.CONFIRM;

  const refRecentVisitedTab = useRef<ESearchTabs>();
  const refRecommendVisitedTab = useRef<ESearchTabs>();
  const refContent = useRef<HTMLDivElement | null>(null);
  const {currentTab, prevTab, changeTab, isSearchMain, isEventTab, isFromTPlaceHome} =
    useSearchMainTab();
  const [lastVisibleTab, setLastVisibleTab] = useState();
  const activeTab = useMemo(() => currentTab || lastVisibleTab, [currentTab, lastVisibleTab]);
  const hasSearchDeepLink = !!getFirstParam(originQueries.query);

  const hideSearchBar = useMemo(
    () => [ESearchTabs.RECENT_EDIT, ESearchTabs.ADDRESS, ESearchTabs.FESTIVAL].includes(activeTab),
    [activeTab]
  );

  const tabOrder = useMemo(
    () =>
      isOver10_6Version()
        ? [ETab.HOME, ETab.OFFICE, ETab.FAVORITE]
        : isConfirmMode
        ? [ETab.HOME, ETab.OFFICE]
        : [ETab.HOME, ETab.OFFICE, ETab.FAVORITE],
    [isConfirmMode]
  );

  const hideTab = useMemo(() => {
    return [
      EAppRequestMode.ROUTE,
      EAppRequestMode.DRIVING,
      EAppRequestMode.FAVORITE,
      EAppRequestMode.REPORT,
    ].includes(reqMode);
  }, [reqMode]);

  const redDotItem = useMemo(() => {
    if (redDotList) {
      return getRedDotItem('TAB_RECOMMEND');
    }
  }, [redDotList, getRedDotItem]);

  const appLayout = useAppLayout();
  useNaverKeywordAd({holdLoad: !userInfo.accessKey});

  const handleClickAddress = useCallback(() => {
    changeTab(ESearchTabs.ADDRESS, {pushHistory: true});
    sendSearchClickLog('tap.address_search');
  }, [changeTab]);

  useOnce(!currentTab && !hide, () => {
    changeTab(ESearchTabs.RECENT, {pushHistory: false});
  });

  // https://tmobi.atlassian.net/browse/CLIENTQA-2799
  useOnce(hasSearchDeepLink, () => {
    const query = getFirstParam(originQueries.query);

    moveToSearch(query);
  });

  useOnce(isFromTPlaceHome, () => {
    changeTab(ESearchTabs.RECOMMEND, {pushHistory: false});
  });

  useEffect(() => {
    if (showMain && currentTab === ESearchTabs.MAP) {
      HybridBridge.showMapSelectButton(false);
      TMapSender.showSoftKeyboard(false);
    } else if (
      showMain &&
      prevTab &&
      prevTab !== ESearchTabs.ADDRESS &&
      currentTab === ESearchTabs.ADDRESS
    ) {
      HybridBridge.queryInputTextNoSuggest('');
    } else if (showMain) {
      HybridBridge.showMapSelectButton(true);
    }
  }, [showMain, showResult, prevTab, currentTab]);

  useEffect(() => {
    return () => {
      if (!hide) {
        setLastVisibleTab(currentTab);
      }
    };
  }, [hide, currentTab]);

  useEffect(() => {
    if (!userInfo.accessKey || !currentTab || hide) {
      return;
    }

    const pageId = PageIdMap[currentTab];

    if (!pageId) {
      return;
    }

    initLog({
      sessionId: userInfo.sessionId,
      accessKey: userInfo.accessKey,
      sessionKey: userInfo.sessionKey,
      userKey: userInfo.userKey,
      deviceId: userInfo.device.deviceId,
      carrierName: userInfo.device.carrierName,
      pageId,
      pageType: EPageType.SEARCH,
      referrer,
    });

    const isRecentFirstVisit = refRecentVisitedTab.current !== currentTab;
    const isRecommendFirstVisit = refRecommendVisitedTab.current !== currentTab;

    if (currentTab === ESearchTabs.RECOMMEND) {
      sendExpose(EPageType.SEARCH, {
        index: isRecommendFirstVisit ? 1 : 0,
        tooltip: !!isCoachMark,
        reddot: !!redDotItem,
        reqMode: reqMode,
        package_version: getCurrentPackageVersion(),
      });
    } else {
      sendExpose(EPageType.SEARCH, {
        index: isRecentFirstVisit ? 1 : 0,
        package_version: getCurrentPackageVersion(),
      });
    }

    if (currentTab === ESearchTabs.RECENT) {
      refRecentVisitedTab.current = currentTab;
    } else if (currentTab === ESearchTabs.RECOMMEND) {
      refRecommendVisitedTab.current = currentTab;
    }

    dispatch(actions.log.setInitialize(true));
  }, [userInfo.accessKey, hide, currentTab]);

  useEffect(() => {
    if (!hide) {
      return;
    }

    dispatch(actions.log.setInitialize(false));
  }, [hide]);

  return (
    <VSMInterfaceProvider>
      <div
        className={classNames(s.main_page_wrap, {
          [s.hide_page]: hide,
          [s.hybrid_page]: isHybrid,
        })}
        style={
          {
            '--hybrid-search-area-height': `${hideSearchBar ? 0 : appLayout.searchBarArea}px`,
            '--hybrid-status-area-height': `${appLayout.statusBarArea}px`,
          } as CSSProperties
        }
      >
        {!isHybrid && !hide && !hideSearchBar && <SearchBar />}
        {activeTab === ESearchTabs.RECENT_EDIT && <SearchRecentListEdit />}
        {activeTab === ESearchTabs.ADDRESS && <SearchAddress />}
        {activeTab === ESearchTabs.MAP && <SearchPickOnMap />}
        {activeTab === ESearchTabs.FESTIVAL && <SearchFestival />}

        {isSearchMain && !isEventTab && (
          <>
            <div className={s.top_action}>
              {reqMode !== EAppRequestMode.FAVORITE && reqMode !== EAppRequestMode.REPORT && (
                <div className={s.tab_list}>
                  {tabOrder.map((tab) => (
                    <SearchTab
                      type={tab}
                      key={tab}
                      isConfirmMode={isConfirmMode}
                      showFavorite={tabOrder.includes(ETab.FAVORITE)}
                    />
                  ))}
                </div>
              )}
              <button className={s.address_btn} onClick={handleClickAddress}>
                주소검색
                <IcArrowRight width={16} height={16} color={'gray800'} />
              </button>
            </div>

            {!hideTab && (
              <SearchMainTab
                wrapper={refContent}
                activeTab={activeTab}
                isRedDot={!!redDotItem}
                updateRedDotList={updateRedDotList}
              />
            )}

            <div ref={refContent} className={s.content}>
              {activeTab === ESearchTabs.RECENT && (
                <SearchMainRecent hideEdit={hideTab} wrapper={refContent} />
              )}
              {activeTab === ESearchTabs.RECOMMEND && <SearchMainRecommend />}
            </div>
          </>
        )}
        {isEventTab && <SearchEventTab />}
        {!hide && <RouteAddPopup />}
      </div>
    </VSMInterfaceProvider>
  );
};
