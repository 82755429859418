import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {TPersonalPlaceItem} from 'ducks/userInfo/types';
import {TSearchPoi, TPoiGroupSub} from 'types/App';
import {TMarker, EMarkerType} from 'types/Map';
import {checkIsActiveItem} from 'utils/map';
import usePersonalMarker from './usePersonalMarker';
import {filterCustomMarker} from 'utils/marker';
import useMapMarkerConfig from './useMapMarkerConfig';
import {ESearchCollectionType} from 'ducks/search/types';
import {isLocalEnv} from 'constant/Env';

type TItem = TSearchPoi | TPoiGroupSub;

const parseItem = ({type, anchor, ...item}) => ({
  properties: item,
  type,
  description: item.listName,
  label: item.listName || item.customName,
  lonLat: item.lonLat || {
    lon: item.wgs84CenterX,
    lat: item.wgs84CenterY,
  },
  anchor,
  children: item.children,
});

const itemToFavoriteMarker = (i, active: boolean): TMarker<Partial<TPersonalPlaceItem>> => ({
  ...i,
  type: active ? EMarkerType.ACTIVE_FAVORITE : EMarkerType.FAVORITE,
  anchor: 'center',
});

const itemToFavoritePublicTransMarker = (
  i,
  active: boolean
): TMarker<Partial<TPersonalPlaceItem>> => ({
  ...i,
  type: active ? EMarkerType.ACTIVE_FAVORITE_PUBLIC_TRANS : EMarkerType.FAVORITE_PUBLIC_TRANS,
  anchor: 'center',
});

const itemToFavoriteHomeMarker = (v, active: boolean) => ({
  ...v,
  type: active ? EMarkerType.ACTIVE_FAVORITE_HOME : EMarkerType.FAVORITE_HOME,
  anchor: 'center',
});

const itemToFavoriteOfficeMarker = (v, active: boolean) => ({
  ...v,
  type: active ? EMarkerType.ACTIVE_FAVORITE_OFFICE : EMarkerType.FAVORITE_OFFICE,
  anchor: 'center',
});

const itemToRecentMarker = (i, active: boolean): TMarker<Partial<TPersonalPlaceItem>> => ({
  ...i,
  type: active ? EMarkerType.ACTIVE_RECENT_DESTINATION : EMarkerType.RECENT_DESTINATION,
  anchor: 'center',
});

const itemToCalloutMarker = (i) => parseItem({...i, type: i.markerType, anchor: 'bottom'});

const useSearchResultMarker = () => {
  const {
    rdSearch,
    activePoi,
    calloutInfo,
    mapStyle,
    showFavorite,
    showRecentlyDestination,
    collectionType,
  } = useAppSelector((state) => ({
    rdSearch: state.search,
    activePoi: state.userInteraction.activePoi,
    calloutInfo: state.userInteraction.calloutInfo,
    mapStyle: state.map.style,
    collectionType: state.search.data.collectionType,
    showFavorite: isLocalEnv ? true : state.userInfo.showFavorite,
    showRecentlyDestination: isLocalEnv ? true : state.userInfo.showRecentlyDestination,
  }));

  const personalMarker = usePersonalMarker();
  const {
    favoriteMarker,
    favoriteHomeMarker,
    favoriteOfficeMarker,
    favoritePublicMarker,
    recentDestinationMarker,
  } = personalMarker;

  const {markerStyleConfig} = useMapMarkerConfig();

  const markers = useMemo(() => {
    let activeMarker;
    let poiMarker;
    let poiBusStopMarker;
    let poiSubwayMarker;

    if (calloutInfo?.markerType === EMarkerType.ACTIVE) {
      const calloutInfoMarker = itemToCalloutMarker({
        ...calloutInfo,
        poiName: calloutInfo.customName,
        lonLat: {
          lon: calloutInfo.lon,
          lat: calloutInfo.lat,
        },
      });

      if (calloutInfo.publicTransportType === 'busstop') {
        poiBusStopMarker = {
          ...calloutInfoMarker,
          type: EMarkerType.ACTIVE_BUS_STATION,
        };
      } else if (calloutInfo.publicTransportType === 'subway') {
        poiSubwayMarker = {
          ...calloutInfoMarker,
          type: EMarkerType.ACTIVE_SUBWAY,
        };
      } else {
        poiMarker = calloutInfoMarker;
      }
    }

    const resultMarkers = rdSearch.searchList
      // https://tmobi.atlassian.net/browse/SEARCH-21 추천검색 subPoi 마커 미노출
      .reduce((arr, l) => {
        const isLtrYn = rdSearch.data?.ltrYn;
        const groupSubList = isLtrYn ? [] : (l as TSearchPoi)?.groupSubList || [];

        return [...arr, l, ...groupSubList] as TItem[];
      }, [] as TItem[])
      .map((v) => {
        const active = !calloutInfo && v.listId === activePoi;
        const {marker: customMarker, label} = filterCustomMarker(v, active, {
          mapStyle,
          markerConfig: markerStyleConfig,
        });
        const activeResultMarker =
          collectionType === ESearchCollectionType.BUS_STATION
            ? EMarkerType.ACTIVE_BUS_STATION
            : EMarkerType.ACTIVE;

        const item = parseItem({
          ...v,
          description: label || v.listName,
          type: active ? activeResultMarker : EMarkerType.INACTIVE,
          anchor: active ? 'bottom' : 'center',
          children: customMarker,
        });

        if (active) {
          activeMarker = item;
        }

        return item;
      });

    const recentDestinationList = showRecentlyDestination
      ? recentDestinationMarker.map((v) => {
          const isActive =
            [EMarkerType.RECENT_DESTINATION, EMarkerType.ACTIVE_RECENT_DESTINATION].includes(
              calloutInfo?.markerType || EMarkerType.ACTIVE
            ) && checkIsActiveItem(v.properties, calloutInfo);
          const item = itemToRecentMarker(v, isActive);

          if (isActive) {
            activeMarker = item;
          }

          return item;
        })
      : [];

    const favoriteList = showFavorite
      ? favoriteMarker.map((v) => {
          const isActive =
            [EMarkerType.FAVORITE, EMarkerType.ACTIVE_FAVORITE].includes(
              calloutInfo?.markerType || EMarkerType.ACTIVE
            ) && checkIsActiveItem(v.properties, calloutInfo);
          const item = itemToFavoriteMarker(v, isActive);

          if (isActive) {
            activeMarker = item;
          }

          return item;
        })
      : [];

    const favoritePublicList = showFavorite
      ? favoritePublicMarker.map((v) => {
          const isActive =
            [EMarkerType.FAVORITE_PUBLIC_TRANS, EMarkerType.ACTIVE_FAVORITE_PUBLIC_TRANS].includes(
              calloutInfo?.markerType || EMarkerType.FAVORITE_HOME
            ) && checkIsActiveItem(v.properties, calloutInfo);
          const item = itemToFavoritePublicTransMarker(v, isActive);

          if (isActive) {
            activeMarker = item;
          }

          return item;
        })
      : [];

    let favoriteHome = null;
    let favoriteOffice = null;

    if (showFavorite && favoriteHomeMarker) {
      const isActive =
        [EMarkerType.FAVORITE_HOME, EMarkerType.ACTIVE_FAVORITE_HOME].includes(
          calloutInfo?.markerType || EMarkerType.FAVORITE_HOME
        ) && checkIsActiveItem(favoriteHomeMarker.properties, calloutInfo);

      const item = itemToFavoriteHomeMarker(favoriteHomeMarker, isActive);

      if (isActive) {
        activeMarker = item;
      }

      favoriteHome = item;
    }

    if (showFavorite && favoriteOfficeMarker) {
      const isActive =
        [EMarkerType.FAVORITE_OFFICE, EMarkerType.ACTIVE_FAVORITE_OFFICE].includes(
          calloutInfo?.markerType || EMarkerType.FAVORITE_OFFICE
        ) && checkIsActiveItem(favoriteOfficeMarker.properties, calloutInfo);

      const item = itemToFavoriteOfficeMarker(favoriteOfficeMarker, isActive);

      if (isActive) {
        activeMarker = item;
      }

      favoriteOffice = item;
    }

    return {
      resultMarkers: resultMarkers.filter((v) => v.lonLat?.lon && v.lonLat?.lat),
      poiMarker,
      poiBusStopMarker,
      poiSubwayMarker,

      activeItem: poiMarker || activeMarker,
      personalInfoMarkers: [
        ...recentDestinationList,
        ...favoriteList,
        ...favoritePublicList,
        favoriteHome,
        favoriteOffice,
      ].filter((v) => !!v),
    };
  }, [rdSearch.searchList, activePoi, calloutInfo, personalMarker, favoritePublicMarker, mapStyle]);

  return markers;
};

export default useSearchResultMarker;
