import ua, {tmapUA} from 'utils/uaParser';
import {isProdEnv, isRtgEnv} from './Env';
import {TAdOption} from 'components/AdBanner';
import TNowBanner from 'resource/pubImages/banner_tnow.jpeg';

export const isBannerTestEnv = !isProdEnv && !isRtgEnv;

export enum EAdType {
  INTERNAL = 'INTERNAL',
  ASUM = 'ASUM',
  MOLOCO = 'MOLOCO',
  TDI = 'TDI',
  NAVER = 'NAVER',
  DEFAULT = 'DEFAULT',
  NONE = 'NONE',
}

// 광고쪽에서 광고 유형을 구분하는 값
export enum EAdCode {
  SEARCH_MAIN = 79,
  SEARCH_RESULT = 80,
  SEARCH_RESULT_LIST = 82,
  PLACE_MAIN = 83,
  RANKING_FIRST = 98,
  RANKING_SECOND = 99,
}

export enum ETMapBannerCode {
  SEARCH_MAIN = 'IN201',
  SEARCH_KEYWORD = 'IN202',
  PLACE_MAIN = 'IN203',
  RANKING_FIRST = 'IN206',
  RANKING_SECOND = 'IN2061',
  PLACE_MAIN_SECOND = 'IN2031',
  DISCOVERY_MAIN = 'IN205',
  DISCOVERY_MAIN_SECOND = 'IN2051',
}

export const AD_SOURCE_MAP: Record<EAdType, string> = {
  [EAdType.INTERNAL]: 'internal',
  [EAdType.ASUM]: 'ASUM',
  [EAdType.TDI]: 'TDI',
  [EAdType.MOLOCO]: 'MOLOCO',
  [EAdType.NAVER]: 'NAVER',
  [EAdType.DEFAULT]: 'DEFAULT',
  [EAdType.NONE]: 'none',
};

export const AD_BANNER_MAX_LOAD_TIME = 2000;
export const AD_BANNER_EXTRA_LOAD_TIME = 300;

export enum EAsumErrorCode {
  NO_ADS_IN_SERVER = 0,
  NOT_VALID_AD_UNIT_ID = 1,
  NETWORK_ERROR = 2,
  INTERNAL_ERROR = 3,
  CALL_BEFORE_AD_LOAD = 4,
}

export enum EAsumAdUnitId {
  SEARCH_MAIN = '2fd7c534-4a80-47b8-b8b8-24e4d8f3ce15',
  SEARCH_RESULT = 'c8abdf1c-018d-416b-9250-6a680f670dc9',
  TEST = '6bd69490-c0e4-11ea-b0ee-02874ad26950',
}

export enum EMolocoAdUnit {
  AOS_NATIVE_MAIN = 'fNpycpTeZ2kdhFci',
  AOS_NATIVE_RESULT = 'wJRwdAWmzzsmOaYl',
  // AOS_BANNER = 'rZm8On0yHZ7MawRM',

  IOS_NATIVE_MAIN = 'O0gkhVNsPmVKOKzg',
  IOS_NATIVE_RESULT = 'J4g8DPRwFJKszWBm',
  // IOS_BANNER = 'MAvzyGOJjWtAc5Ea',

  IOS_SAMPLE_NATIVE = '2ceaacd9e6634328abd4a77be7c8656c',

  AOS_PLACE_MAIN = 'rMHWwEdYBciHSUjd',
  IOS_PLACE_MAIN = 'PxzPGlozw0Sv3um2',
}

export enum EMolocoOs {
  IOS = 'ios',
  ANDROID = 'android',
}

export enum EMolocoDeviceOrientation {
  PORTRAIT = 'p',
  LANDSCAPE = 'l',
}

export enum EMolocoAdTypes {
  BANNER = 1,
  NATIVE = 2,
  INTERSTITIAL = 3,
  AUDIO = 4,
}

export enum EMolocoEndPoint {
  APAC = 'https://adservfnt-asia.adsmoloco.com/adserver/v1',
  AMERICA = 'https://adservfnt-us.adsmoloco.com/adserver/v1',
  EU = 'https://adservfnt-eu.adsmoloco.com/adserver/v1',
}

export const MOLOCO_TEST_ADID = '9759e029-e270-4841-83c0-477963c97747';
export const MOLOCO_IOS_TEST_ADID = 'BEF9E798-A054-4365-847D-6FD873EE5F69';

export const MOLOCO_NATIVE_ASSETS = encodeURIComponent(
  ['title', 'text', 'iconimage', 'mainimage', 'ctatext', 'customdata'].join(',')
);

export enum EDeviceType {
  // 2.0
  MOBILE_TABLET = 1,
  PC = 2,
  TV = 3,

  // 2.2
  PHONE = 4,
  TABLET = 5,
  CONNECTED_DEVICE = 6,
  SET_TOP_BOX = 7,
}

export const MOLOCO_SAMPLE_DEVICE = {
  deviceModel: ua.isIos ? 'iPhone' : 'SM-S908N',
  carrier: 'SKTelecom',
  osv: ua.isIos ? '15.5' : '12',
};

export const MOLOCO_SAMPLE_LOAD_OPTION = {
  adid: ua.isIos ? MOLOCO_IOS_TEST_ADID : MOLOCO_TEST_ADID,
};

export enum ENaverDaUnitId {
  TEST = 'WEB_nw_native_1-N345765840',
  SEARCH_MAIN = 'Tmap_AdUnit_his-N774476171',
  SEARCH_RESULT = 'Tmap_AdUnit_res-N774476171',
  PLACE_MAIN = 'Tmap_AdUnit_place-N774476171',
  RANKING_FIRST = 'Tmap_AdUnit_ranking_no2-N774476171',
  RANKING_SECOND = 'Tmap_AdUnit_ranking_no21-N774476171',
  PLACE_MAIN_SECOND = 'Tmap_AdUnit_place_no21-N774476171',
  DISCOVERY_MAIN = 'Tmap_AdUnit_place_discovery_1-N774476171',
  DISCOVERY_MAIN_SECOND = 'Tmap_AdUnit_place_discovery_2-N774476171',
}

export enum ENaverSABucket {
  CONTROL = 0,
  BUCKET_1 = 1,
  BUCKET_2 = 2,
}

export enum EDefaultAdPosition {
  PLACE_MAIN = 'TNOW',
  TOTAL_SEARCH = 'TOTAL_SEARCH',
}

export const NAVER_SA_JS_PATH =
  'https://ssl.pstatic.net/adimg3.search/adpost/js/adpost_show_ads_v2.min.js';
export const NAVER_SA_FUNCTION_NAME = 'NAVER_ADPOST_V2';

export const NAVER_SA_CHANNEL = 'm_tmap.ch1';

export const NAVER_SA_POSITION_IDX = 2;

const TMAP_BANNER_MIN_SUPPORT = 10;

const filterInternal = (types: EAdType[]) => {
  const isNotSupportInternal = ua.isInApp && tmapUA.tmapInfo.major < TMAP_BANNER_MIN_SUPPORT;

  if (isNotSupportInternal) {
    return types.filter((t) => t !== EAdType.INTERNAL);
  }
  return types;
};

const TNOW_DEFAULT_BANNER = {
  imageUrl: TNowBanner,
  landingLink: 'tmap://nearby?reqKey=TNOW',
};

export const PLACE_BANNER_AD_STEP = filterInternal([
  EAdType.INTERNAL,
  EAdType.MOLOCO,
  EAdType.NAVER,
]);

export const PLACE_BANNER_DEFAULT_AD_STEP = filterInternal([EAdType.INTERNAL, EAdType.NAVER]);

export const PLACE_BANNER_PROVIDER_CONFIG: TAdOption = {
  inner: {
    inventoryCode: ETMapBannerCode.PLACE_MAIN,
  },
  moloco: {
    adUnitId: ua.isIos ? EMolocoAdUnit.IOS_PLACE_MAIN : EMolocoAdUnit.AOS_PLACE_MAIN,
  },
  naver: {
    adUnitId: isBannerTestEnv ? ENaverDaUnitId.TEST : ENaverDaUnitId.PLACE_MAIN,
  },
};

export const PLACE_BANNER_SECOND_PROVIDER_CONFIG: TAdOption = {
  inner: {
    inventoryCode: ETMapBannerCode.PLACE_MAIN_SECOND,
  },
  naver: {
    adUnitId: isBannerTestEnv ? ENaverDaUnitId.TEST : ENaverDaUnitId.PLACE_MAIN_SECOND,
  },
};

export const DISCOVERY_BANNER_PROVIDER_CONFIG: TAdOption = {
  inner: {
    inventoryCode: ETMapBannerCode.DISCOVERY_MAIN,
  },
  naver: {
    adUnitId: isBannerTestEnv ? ENaverDaUnitId.TEST : ENaverDaUnitId.DISCOVERY_MAIN,
  },
};

export const DISCOVERY_BANNER_SECOND_PROVIDER_CONFIG: TAdOption = {
  inner: {
    inventoryCode: ETMapBannerCode.DISCOVERY_MAIN_SECOND,
  },
  naver: {
    adUnitId: isBannerTestEnv ? ENaverDaUnitId.TEST : ENaverDaUnitId.DISCOVERY_MAIN_SECOND,
  },
};

export const SEARCH_BANNER_AD_STEP: EAdType[] = filterInternal([
  EAdType.INTERNAL,
  EAdType.ASUM,
  EAdType.MOLOCO,
  EAdType.NAVER,
  EAdType.DEFAULT,
]);

export const RANKING_BANNER_AD_STEP: EAdType[] = filterInternal([
  EAdType.INTERNAL,
  // EAdType.ASUM, // TODO: ASUM unitId 요청 확인 후 추가
  // EAdType.MOLOCO, // TODO: MOLOCO unitId 요청 확인 후 추가
  EAdType.NAVER,
  EAdType.DEFAULT,
]);

export const SEARCH_RESULT_BANNER_PROVIDER_CONFIG: TAdOption = {
  inner: {
    inventoryCode: ETMapBannerCode.SEARCH_KEYWORD,
  },
  asum: {
    adUnitId: EAsumAdUnitId.SEARCH_RESULT,
  },
  moloco: {
    adUnitId: ua.isIos ? EMolocoAdUnit.IOS_NATIVE_RESULT : EMolocoAdUnit.AOS_NATIVE_RESULT,
  },
  naver: {
    adUnitId: ENaverDaUnitId.SEARCH_RESULT,
  },
  default: TNOW_DEFAULT_BANNER,
};

export const SEARCH_MAIN_BANNER_PROVIDER_CONFIG: TAdOption = {
  inner: {
    inventoryCode: ETMapBannerCode.SEARCH_MAIN,
  },
  asum: {
    adUnitId: EAsumAdUnitId.SEARCH_MAIN,
  },
  moloco: {
    adUnitId: ua.isIos ? EMolocoAdUnit.IOS_NATIVE_MAIN : EMolocoAdUnit.AOS_NATIVE_MAIN,
  },
  naver: {
    adUnitId: ENaverDaUnitId.SEARCH_MAIN,
  },
  default: TNOW_DEFAULT_BANNER,
};

export const TEST_BANNER_PROVIDER_CONFIG: Partial<TAdOption> = {
  asum: {
    adUnitId: EAsumAdUnitId.TEST,
  },
  moloco: ua.isIos
    ? {
        adUnitId: EMolocoAdUnit.IOS_SAMPLE_NATIVE,
      }
    : undefined,
  naver: {
    adUnitId: ENaverDaUnitId.TEST,
  },
  default: TNOW_DEFAULT_BANNER,
};

export const AD_CLEANUP_WAIT_TIME = 10;
