import {EFilter} from 'components/FilterBottomPopup';
import {TAddressMap} from 'constant/Address';
import {ECategoryCode} from 'constant/Place';
import {TApiResponse, TApiStatus} from 'types/Api';
import {ECategoryGroup, TPlaceTagItem, TPoiImageInfo, TSpecialData} from 'types/App';
import {ESortOption} from 'types/Search';

export const TRANK_MAX_COUNT = 50;

// WIKI: https://tmobi.atlassian.net/wiki/spaces/LEGORENEW/pages/787004981 sort 정렬기준 참고.
export enum ETRankSort {
  DEFAULT = 'score',
  DISTANCE = 'distance',
  SCORE_WEEK = 'score_week',
  TMAP_RANK = 'call_cnt_term_b',
}

export type TRankItem = {
  pkey: string;
  poiId: string;
  poiName: string;
  categoryName: string;
  svcCategoryName: string;
  listId: string;
  rank: number;

  images: string[];
  imageInfo: TPoiImageInfo[];
  addressNameDepth2: string;
  fullRoadAddr: string;
  fullJibunAddr: string;
  categoryCode: string;

  categoryGroup: ECategoryGroup;
  isPopular: boolean;
  isParking: boolean;
  headingForTick: number;
  headingForScore: number;
  headingForScoreYesterday: number;
  headingForWeekScore: number;
  distance: number;
  userRealDistance: number;
  wgs84CenterX: number;
  wgs84CenterY: number;
  navX: number;
  navY: number;
  centerX: number;
  centerY: number;

  mainCategory: string;
  markerCategory: string;
  special: TSpecialData;
  comment: Nullable<string>;
  tag: TPlaceTagItem;
};

export type TRankResult = {
  list: TRankItem[];
};

type TFilterInfo = {
  isActive: boolean;
  key: EFilter | ECategoryCode;
  label: string;
};

export type TRankState = TApiStatus<TRankResult> & {
  currentAddressMap: TAddressMap;
  activeFilters: Partial<Record<EFilter | ECategoryCode, TFilterInfo>>;
  activeSortOption: Partial<Record<ESortOption, boolean>>;
  displayList: TRankItem[];
};

export type TRankPayload = TApiResponse<{
  areaResponse: object;
  latestUpdateTime: string;
  docs: TRankItem[];
  totalCount: number;
}>;

export type TRankProps = {
  lon?: number;
  lat?: number;
  userRealLon?: number;
  userRealLat?: number;
  playEatYn?: string;
  headingScoreFrom?: number;
  headingScoreTo?: number;
  page?: number;
  size?: number;
  sort?: string;
  categoryTypes?: string[];
  areaCode1?: string;
  areaCode2?: string;
  areaCode3?: string;
  feature?: string;
  featureOr?: string;
  featureAnd?: string;
};
