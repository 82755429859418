import ImageLoader from 'components/ImageLoader';
import {TTPlaceCurationType2} from 'ducks/remote/type';
import {moveByLandingType} from 'utils/url';
import {IcLineBetween} from 'components/@tmds/icons/v1.2/IcLineBetween';
import s from 'styles/components/tplacehome/TPlaceCurationType2.module.scss';
import {useAppSelector} from 'ducks/hooks';
import usePlaceHome from 'hooks/usePlaceHome';

type TProps = {
  item: TTPlaceCurationType2;
};

const TPlaceCurationType2 = ({item}: TProps) => {
  const {userInfo} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();
  return (
    <div className={s.wrap}>
      <div className={s.title_wrap}>
        <div className={s.title_image_wrap}>
          <ImageLoader src={item.thumbnailUrl} alt={item.title} className={s.bg} />
          <div className={s.gradient} />
        </div>

        <div className={s.title}>
          <h4 dangerouslySetInnerHTML={{__html: item.title}} />
          <span className={s.desc} dangerouslySetInnerHTML={{__html: item.desc}} />
        </div>
      </div>

      {item.list.length > 0 && (
        <ul className={s.list_wrap}>
          {item.list.map((v, i) => (
            <li
              key={i}
              className={s.item}
              onClick={() => {
                placehomeHook.sendEvent('tap.curating_contents', {
                  euk: userInfo.euk,
                  curating_contents_type: item.type,
                });
                moveByLandingType(v.landingType, v.landingUrl);
              }}
            >
              <ImageLoader src={v.thumbnailUrl} alt={v.poiName} className={s.image} />
              <div className={s.poi_wrap}>
                <span className={s.poi_name}>{v.poiName}</span>

                <div className={s.detail_wrap}>
                  <span className={s.address}>{v.address}</span>
                  {/* TODO: color 수정 */}
                  <IcLineBetween color="#E0E2E8" width={10} height={10} />
                  <span className={s.category}>{v.category}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TPlaceCurationType2;
