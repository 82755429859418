import {useCallback, useEffect, useRef, useState} from 'react';
import {useLocalStorage} from '@lcc/web-storage';
import {StorageKey} from '../constant/Storage';
import {ESearchTabs} from '../types/Search';
import {useSearchMainTab} from './useSearchMainTab';

/**
 * READY: 오픈 전
 * OPEN: 오픈
 * CLOSE: 닫기
 * END: 닫기 애니메이션 종료
 */
export enum CoachMarkStatus {
  READY,
  OPEN,
  CLOSE,
  END,
}

const TIME = 4000;

const useCoachMark = () => {
  const {currentTab} = useSearchMainTab();

  const [isCoachMark, setCoachMark] = useLocalStorage<boolean>(
    StorageKey.SEARCH_COACH_MARK,
    currentTab !== ESearchTabs.RECOMMEND
  );
  const [openCoachMark, setOpenCoachMark] = useState<CoachMarkStatus>(CoachMarkStatus.READY);

  const timerId = useRef(0);

  const handleCoachMarkTransitionEnd = useCallback(() => {
    openCoachMark === CoachMarkStatus.CLOSE && setOpenCoachMark(CoachMarkStatus.END);
    setCoachMark(false);
  }, [openCoachMark, setCoachMark]);

  useEffect(() => {
    setOpenCoachMark(CoachMarkStatus.OPEN);
  }, []);

  useEffect(() => {
    if (openCoachMark === CoachMarkStatus.OPEN) {
      timerId.current = window.setTimeout(() => {
        setOpenCoachMark(CoachMarkStatus.CLOSE);
      }, TIME);
      return () => clearTimeout(timerId.current);
    }
  }, [openCoachMark]);

  return {
    isCoachMark,
    openCoachMark,
    handleCoachMarkTransitionEnd,
  };
};

export default useCoachMark;
