import {Fragment, ReactNode} from 'react';
import LocalStorage from '@lcc/web-storage';
import {escapeRegExp} from 'utils/lodash';
import {devLog} from 'utils/dev';
import {StorageKey} from 'constant/Storage';
import {ESearchLocationType} from 'types/Search';
import {EAppRequestMode, EPoiType, ERPFlag} from 'types/App';
import {TPoiDataJson} from '@lcc/tmap-inapp';

const CHAR_START_OFFSET = 44032; // '가'
// const CHART_CODE_ㅅ = 12613;
const COUNT_JONGSUNG = 28;
const COUNT_CHAR = 588;

// ㄱ ~ ㅃ 까지는 초성과 종성이 순서가 안맞아 단순 계산이 안되니 첫글자로 매핑.
// ㅅ 이후 부터는 ㅅ부터의 index에 해당 글자갯수를 곱한 후 '사'코드를 더해주면 해당 코드가 나옴
// 근데 상수로 만드는게 더 나아 보인다..
const WORD_CODES_FROM_CHOSUNG = {
  ㄱ: 44032, // '가'.charCodeAt(0),
  ㄲ: 44620, // '까'.charCodeAt(0),
  ㄴ: 45208, // '나'.charCodeAt(0),
  ㄷ: 45796, // '다'.charCodeAt(0),
  ㄸ: 46384, // '따'.charCodeAt(0),
  ㄹ: 46972, // '라'.charCodeAt(0),
  ㅁ: 47560, // '마'.charCodeAt(0),
  ㅂ: 48148, // '바'.charCodeAt(0),
  ㅃ: 48736, // '빠'.charCodeAt(0),
  ㅅ: 49324, // '사'.charCodeAt(0),

  // 여기부터 연산으로 가능하지만 상수화가 나아보임
  ㅆ: 49912, // '싸'.charCodeAt(0),
  ㅇ: 50500, // '아'.charCodeAt(0),
  ㅈ: 51088, // '자'.charCodeAt(0),
  ㅉ: 51676, // '짜'.charCodeAt(0),
  ㅊ: 52264, // '차'.charCodeAt(0),
  ㅋ: 52852, // '카'.charCodeAt(0),
  ㅌ: 53440, // '타'.charCodeAt(0),
  ㅍ: 54028, // '파'.charCodeAt(0),
  ㅎ: 54616, // '하'.charCodeAt(0),
};

export const getCharPattern = (c) => {
  if (/[ㄱ-ㅎ]/.test(c)) {
    const begin = WORD_CODES_FROM_CHOSUNG[c];
    // 아래 식으로 ㅅ 이후의 시작값을 구할 수는 있음
    // || (c.charCodeAt(0) - CHART_CODE_ㅅ) * COUNT_CHAR + WORD_CODES_FROM_CHOSUNG['ㅅ'];
    const end = begin + COUNT_CHAR - 1;

    return `[${c}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
  }

  if (/[가-힣]/.test(c)) {
    const code = c.charCodeAt(0) - CHAR_START_OFFSET;

    // 종성까지 있을때는 c 자체 사용
    if (code % COUNT_JONGSUNG > 0) {
      return c;
    }

    // 종성 범위
    const begin = Math.floor(code / COUNT_JONGSUNG) * COUNT_JONGSUNG + CHAR_START_OFFSET;
    const end = begin + COUNT_JONGSUNG - 1;

    return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
  }

  if (/[a-z]/) {
    return `[${c}${c.toUpperCase()}]`;
  }

  if (/[A-Z]/) {
    return `[${c}${c.toLowerCase()}]`;
  }

  // 나머지 한글아닌 문자는 그대로 사용
  return escapeRegExp(c);
};

export const getSearchRegExp = (searchQuery) => {
  const searchPattern = searchQuery.replace(/\s/g, '').split('').map(getCharPattern).join('\\s*');

  devLog('[searchPattern]', searchPattern);

  return {
    searchRegExp: searchPattern ? new RegExp(`^${searchPattern}`) : undefined,
    highlightRegExp: searchPattern ? new RegExp(`${searchPattern}`) : undefined,
  };
};

export const highlightQuery = (query: string, regExp?: RegExp): string | ReactNode[] => {
  if (!regExp) {
    return query;
  }

  const matched = regExp.exec(query);

  if (matched) {
    const [frontString, ...remains] = query.split(matched[0]);

    return [frontString, <em>{matched[0]}</em>, remains.join(matched[0])]
      .filter((n) => n)
      .map((n, i) => <Fragment key={i}>{n}</Fragment>);
  }

  return query;
};

export const UserSearchLocationType = {
  get: () => LocalStorage.getItem(StorageKey.SEARCH_LOCATION_TYPE) || ESearchLocationType.USER,
  set: (item: ESearchLocationType) => LocalStorage.setItem(StorageKey.SEARCH_LOCATION_TYPE, item),
};

export const getListName = (data) => {
  if (data.poiType === EPoiType.ROAD) {
    return data.fullRoadAddr || data.fullJibunAddr;
  } else if (data.poiType === EPoiType.JIBUN) {
    return data.fullJibunAddr || data.fullRoadAddr;
  }

  return data.poiName || data.busStationName;
};

export const getFirstParam = (queries: string | (string | null)[] | null) => {
  if (!queries) {
    return '';
  }

  return (typeof queries === 'string' ? queries : queries[0]) || '';
};

export const parsePoiInfoToNavInfo = (poiInfo: TPoiDataJson): TPoiDataJson => {
  return {
    pkey: `${poiInfo.pkey || ''}`,
    poiId: `${poiInfo.poiId || ''}`,
    navSeq: `${poiInfo.navSeq || ''}`,
    navX: `${poiInfo.navX || ''}`,
    navY: `${poiInfo.navY || ''}`,
    centerX: `${poiInfo.centerX || ''}`,
    centerY: `${poiInfo.centerY || ''}`,
    rpFlag: poiInfo.rpFlag || ERPFlag.N_G000,

    poiName: poiInfo.poiName || '',
    address: poiInfo.address || '',
    tel: poiInfo.tel || '',

    ...(poiInfo.stationId && {
      stationId: `${poiInfo.stationId}`,
      publicTransportType: poiInfo.publicTransportType,
    }),
  };
};

export const parseAppAccessParam = (queries) => {
  let strReqMode, numReqType, strExtraCode;

  if (queries) {
    if (queries.reqMode) {
      strReqMode = typeof queries.reqMode === 'string' ? queries.reqMode : queries.reqMode[0];

      // https://tmobi.atlassian.net/browse/LOCALQA-662
      // AOS 에서는 특정 reqMode 만 길찾기 허용
      if (strReqMode === EAppRequestMode.TPLACE_HOME) {
        strReqMode = EAppRequestMode.MAIN;
      }
    }

    if (queries.reqType) {
      const strReqType = typeof queries.reqType === 'string' ? queries.reqType : queries.reqType[0];
      numReqType = parseInt(strReqType, 10) || 0;
    }

    if (queries.extra) {
      strExtraCode = typeof queries.extra === 'string' ? queries.extra : queries.extra[0];
    }

    if (queries.tailParam) {
      if (queries.tailParam.reqMode) {
        strReqMode = queries.tailParam.reqMode;
      }
      // 현재 사용하는 것은 reqMode 하나 뿐이라서 주석처리 (이벤트 poi 처리시)
      // if (queries.tailParam.reqType) {
      //   const strReqType = queries.tailParam.reqType;
      //   numReqType = parseInt(strReqType, 10) || 0;
      // }

      // if (queries.tailParam.extra) {
      //   strExtraCode = queries.tailParam.extra;
      // }
    }
  }

  return {reqMode: strReqMode, reqType: numReqType, extra: strExtraCode};
};
