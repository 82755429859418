import useCoachMark, {CoachMarkStatus} from '../../hooks/useCoachMark';

import classNames from 'classnames';

import s from '../../styles/pages/SearchMainPage.module.scss';

const SearchMainTabCoachMark = () => {
  const {isCoachMark, openCoachMark, handleCoachMarkTransitionEnd} = useCoachMark();

  return (
    <>
      {!isCoachMark ||
        (openCoachMark < CoachMarkStatus.END && (
          <p
            className={classNames(s.coach_mark, {
              [s.active]: openCoachMark === CoachMarkStatus.OPEN,
            })}
            onTransitionEnd={handleCoachMarkTransitionEnd}
          >
            <object
              data={require('../../resource/images/search_recommend_coach_mark.svg').default}
              aria-label={'새로운 추천탭이 생겼어요'}
            />
          </p>
        ))}
    </>
  );
};

export default SearchMainTabCoachMark;
