import {useCallback, useEffect, useState} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {categoryTypeList, EAdditionalInfo, ECategoryType} from 'ducks/tplacehome/types';
import BottomSheetPopup from 'components/tplacehome/shared/BottomSheetPopup';
import s from 'styles/components/tplacehome/TPlacePopupBase.module.scss';
import classNames from 'classnames';
import BottomSheetPopupButtonSet from 'components/tplacehome/shared/BottomSheetPopupButtonSet';
import usePlaceHome from 'hooks/usePlaceHome';
import {aroundMeDefaultSelectableFilters} from 'ducks/tplacehome/slice';

type TProps = {
  isOpen: boolean;
  onClickSubmit: (filters: {
    additionalInfos: EAdditionalInfo[];
    categoryGroups: ECategoryType[];
  }) => void;
};

const ParamSelectPopup = ({onClickSubmit, isOpen}: TProps) => {
  const {tplacehome} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const [additionalInfos, setAdditionalInfos] = useState(
    tplacehome.aroundMeData.selectedFilters.additionalInfos
  );
  const [categoryGroups, setCategoryGroups] = useState(
    tplacehome.aroundMeData.selectedFilters.categoryGroups
  );

  const onClickAdditionalInfo = useCallback(
    (value: EAdditionalInfo) => {
      if (additionalInfos.includes(value)) {
        setAdditionalInfos(additionalInfos.filter((item) => item !== value));
      } else {
        setAdditionalInfos([...additionalInfos, value]);
      }
    },
    [additionalInfos]
  );

  const onClickCategoryGroups = useCallback(
    (value: ECategoryType) => {
      if (categoryGroups.includes(value)) {
        setCategoryGroups(categoryGroups.filter((item) => item !== value));
      } else {
        setCategoryGroups([...categoryGroups, value]);
      }
    },
    [categoryGroups]
  );

  // 계속 렌더된 상태로 있어서 리셋해줘야 함
  const resetStateByStore = useCallback(() => {
    setAdditionalInfos(tplacehome.aroundMeData.selectedFilters.additionalInfos);
    setCategoryGroups(tplacehome.aroundMeData.selectedFilters.categoryGroups);
  }, [
    tplacehome.aroundMeData.selectedFilters.additionalInfos,
    tplacehome.aroundMeData.selectedFilters.categoryGroups,
  ]);
  useEffect(() => {
    isOpen && resetStateByStore();
  }, [isOpen, resetStateByStore]);

  const resetStateByDefaults = useCallback(() => {
    const defaultData = aroundMeDefaultSelectableFilters;
    setAdditionalInfos(defaultData.additionalInfos);
    setCategoryGroups(defaultData.categoryGroups);
  }, []);

  return (
    <BottomSheetPopup
      isOpen={isOpen}
      contentComponent={
        <div className={s.content_wrap}>
          <div className={s.button_group}>
            <div className={s.title}>부가정보</div>
            <div className={s.list}>
              {placehomeHook.computedAdditionalFilterList.map((item) => (
                <button
                  key={item.value}
                  onClick={() => onClickAdditionalInfo(item.value)}
                  className={classNames(s.rounded_button, {
                    [s.checked]: additionalInfos.includes(item.value),
                  })}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
          <div className={s.button_group}>
            <div className={s.title}>업종</div>
            <div className={s.list}>
              {categoryTypeList.map((item) => (
                <button
                  key={item.value}
                  onClick={() => onClickCategoryGroups(item.value)}
                  className={classNames(s.rounded_button, {
                    [s.checked]: categoryGroups.includes(item.value),
                  })}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      }
      bottomComponent={
        <BottomSheetPopupButtonSet
          onClickReset={() => resetStateByDefaults()}
          onClickConfirm={() => {
            placehomeHook.sendEvent('tap.filter_all_popup_select', {
              tab_filter: [...additionalInfos, ...categoryGroups],
            });
            onClickSubmit({additionalInfos, categoryGroups});
          }}
          confirmText="필터 적용"
        />
      }
    />
  );
};

export default ParamSelectPopup;
