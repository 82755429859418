import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useSearchAddress from '../../hooks/useSearchAddress';
import Header from '../Header';
import {EButtonType} from '../../types/Button';
import classNames from 'classnames';
import {EAddressMode, TAddressItem} from '../../types/Search';
import {sendSearchClickLog} from '../../utils/logManager';
import HybridBridge from '../../utils/searchBar';
import {useSearchMainTab} from '../../hooks/useSearchMainTab';
import {useAppDispatch, useAppSelector} from '../../ducks/hooks';
import {fetchFestival} from '../../ducks/searchRecommend/slice';
import ErrorReload from '../ErrorReload';
import {useOnce} from '../../hooks/useOnce';
import SearchScrollTop from './SearchScrollTop';
import {useSearchPageVisible} from '../../hooks/useSearchPageVisible';
import useCenterTabAlignment from '../../hooks/useCenterTabAlignment';
import SearchRecommendFestivalList from './SearchRecommendFestivalList';
import {TFestivalItem} from '../../ducks/searchRecommend/types';
import InView from 'react-intersection-observer';

import s from '../../styles/components/search/SearchFestival.module.scss';

const AREA_ALL: TAddressItem = {
  areaId: '0',
  areaName: '전체',
  areaDepth1Code: '',
  areaDepth2Code: '',
  areaDepth3Code: '',
};

const SearchFestivalPage = () => {
  const {festivalApi} = useAppSelector((state) => ({
    festivalApi: state.searchRecommend.festivalData.result,
  }));

  const dispatch = useAppDispatch();
  const address = useSearchAddress({depth: parseInt(EAddressMode.CATE1, 10), isPlace: true});
  const {showMain} = useSearchPageVisible();
  const {moveBack} = useSearchMainTab();
  const {tabRef, handleTabActivity} = useCenterTabAlignment();

  const refContent = useRef<HTMLDivElement | null>(null);

  const [initLoad, setInitLoad] = useState(false);
  const [isError, setError] = useState<boolean>();
  const [currentArea, setCurrentArea] = useState(AREA_ALL);
  const [festivalList, setFestivalList] = useState<TFestivalItem[]>([]);

  const areas = useMemo(() => {
    if (address.data) {
      return [AREA_ALL, ...address.data];
    }
  }, [address]);

  const handleClickArea = useCallback(
    (item: TAddressItem, index: number) => {
      if (currentArea.areaId === item.areaId) {
        return;
      }
      sendSearchClickLog('tap.festival_regionfilter', {
        index,
        region_name: item.areaName,
      });
      dispatch(fetchFestival({regionDepth1Code: item.areaDepth1Code}));
      refContent.current?.scrollTo(0, 0);
      handleTabActivity(index);
      setFestivalList([]); // 지역을 변경하면 리스트 초기화
      setCurrentArea(item);
    },
    [currentArea, dispatch, handleTabActivity]
  );

  const handelFetchMore = useCallback(() => {
    const {currentPage, totalPage} = festivalApi.data;
    if (currentPage < totalPage) {
      dispatch(
        fetchFestival({regionDepth1Code: currentArea.areaDepth1Code, page: currentPage + 1})
      );
    }
  }, [festivalApi, currentArea, dispatch]);

  useOnce(!initLoad, () => {
    dispatch(fetchFestival({}));
  });

  useEffect(() => {
    if (address.loaded && festivalApi.loaded) {
      setInitLoad(true);
    }
  }, [address, festivalApi]);

  useEffect(() => {
    const {loaded, data} = festivalApi;
    if (loaded) {
      setFestivalList((prev) => {
        if (!prev.length) {
          return data.festivalInfos;
        }
        return [...prev, ...data.festivalInfos];
      });
    }
  }, [festivalApi]);

  useEffect(() => {
    if (address.loaded && festivalApi.loaded) {
      setError(!!(address.error || festivalApi.error));
    }
  }, [address, festivalApi]);

  useEffect(() => {
    if (!showMain) {
      return;
    }
    HybridBridge.showSearchBar(false);
    return () => HybridBridge.showSearchBar(true);
  }, [showMain]);

  if (isError) {
    return (
      <div className={s.error}>
        <ErrorReload top={0} onReload={() => window.location.reload()} />
      </div>
    );
  }

  return (
    <div className={s.wrap}>
      <div className={s.header}>
        <Header
          leftButton={EButtonType.BACK}
          onGoBack={() => {
            sendSearchClickLog('tap.back');
            HybridBridge.queryInputTextNoSuggest('');
            moveBack();
          }}
          title={'가볼만한 축제'}
        />
      </div>
      {initLoad && (
        <div className={s.container}>
          <ul ref={tabRef} className={s.tab}>
            {areas?.map((item, index) => {
              return (
                <li key={item.areaId}>
                  <button
                    type="button"
                    className={classNames(s.item, {
                      [s.active]: item.areaId === currentArea.areaId,
                    })}
                    onClick={() => handleClickArea(item, index)}
                  >
                    {item.areaName}
                  </button>
                </li>
              );
            })}
          </ul>
          <div ref={refContent} className={s.content}>
            <SearchRecommendFestivalList
              data={festivalList}
              region={currentArea}
              loaded={festivalApi.loaded}
              isVerticalType
            />
            {festivalApi.data.currentPage < festivalApi.data.totalPage && (
              <InView
                onChange={(isVisible) => {
                  isVisible && !festivalApi.loading && handelFetchMore();
                }}
                threshold={0.9}
              >
                <div className={s.visible_dom} />
              </InView>
            )}
            {festivalApi.loaded && <SearchScrollTop data={festivalList} wrapper={refContent} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFestivalPage;
