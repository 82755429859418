import {useCallback} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {compareFavorite} from 'utils/map';

type TFavoriteItemType = string | number;
type TCheckIsFavoriteParams = {
  stationId?: TFavoriteItemType;
  pkey?: TFavoriteItemType;
  navX?: TFavoriteItemType;
  navY?: TFavoriteItemType;
};

const useFavorite = () => {
  const {personalPlace} = useAppSelector((state) => ({
    personalPlace: state.userInfo.personalPlace,
  }));

  const checkIsFavorite = useCallback(
    (data: TCheckIsFavoriteParams) => {
      if (!personalPlace.loaded || !data) {
        return false;
      }

      const {favorites, home, office, favoritesPublic} = personalPlace.data;
      const favoriteItem = [...favorites, ...favoritesPublic, home, office]
        .filter((v) => !!v)
        .find((f) => (f ? compareFavorite(f, data) : false));

      // 이전 비교 로직. 주소쪽 동작 검증 확인 예정
      // return (
      //   v.poiId === poiJson.poiId ||
      //   v.pkey === addressData?.roadPkey ||
      //   v.pkey === addressData?.jibunPkey ||
      //   // v.customName === poiJson.poiName ||
      //   // v.customName === poiJson.address ||
      //   (v.centerX === addressData?.centerX && v.centerY === addressData?.centerY)
      // );

      return !!favoriteItem;
    },
    [personalPlace]
  );

  return {
    checkIsFavorite,
  };
};

export default useFavorite;
