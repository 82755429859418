import {EMarkerType, TBounds, TLonLat} from 'types/Map';
import Vsm, {Point} from '@vsm/vsm';
import {TFavoriteItem, TReverseGeocoding} from 'types/App';
import {DEFAULT_MAX_BOUNDS, MAP_LEVEL_ADJUST_VALUE} from 'constant/Map';
import {EMapFontSize} from '@lcc/tmap-inapp';
import turfDistance from '@turf/distance';

export const getBoundsBox = (coords: TLonLat[]): TBounds => {
  const result = coords.reduce(
    (prev, {lon, lat}) => {
      return lon && lat
        ? {
            s: prev.s > lon ? lon : prev.s,
            w: prev.w < lat ? lat : prev.w,
            n: prev.n < lon ? lon : prev.n,
            e: prev.e > lat ? lat : prev.e,
          }
        : prev;
    },
    {s: 9999, w: 0, n: 0, e: 9999}
  );

  return [result.s, result.w, result.n, result.e];
};

export const getNewBoundsBox = (coords: TLonLat[]): TBounds => {
  const result = coords.reduce(
    (prev, {lon, lat}) => {
      return lon && lat
        ? {
            s: prev.s > lat ? lat : prev.s,
            w: prev.w > lon ? lon : prev.w,
            n: prev.n < lat ? lat : prev.n,
            e: prev.e < lon ? lon : prev.e,
          }
        : prev;
    },
    {
      s: DEFAULT_MAX_BOUNDS.north,
      w: DEFAULT_MAX_BOUNDS.east,
      n: DEFAULT_MAX_BOUNDS.south,
      e: DEFAULT_MAX_BOUNDS.west,
    }
  );

  return [result.w, result.s, result.e, result.n];
};

export const checkInsideBoundsBox = ({bounds, lonlat}: {bounds: TLonLat[]; lonlat: TLonLat}) => {
  const [w, s, e, n] = getNewBoundsBox(bounds);
  const {lat, lon} = lonlat;

  return lat <= n && lat >= s && lon >= w && lon <= e;
};

type TLngLat = {
  lng: number;
  lat: number;
};

export const convertToLonLat = (lngLat?: TLngLat): TLonLat | undefined => {
  if (lngLat) {
    return {lat: lngLat.lat, lon: lngLat.lng};
  }
  return undefined;
};

export const convertToVSMPosition = (lonLat?: TLonLat | undefined): TLngLat | undefined => {
  if (lonLat) {
    return {lat: lonLat.lat, lng: lonLat.lon};
  }
  return undefined;
};

export const getValidLonLat = (
  {lat, lon}: {lat: any; lon: any} = {lat: undefined, lon: undefined}
): TLonLat | undefined => {
  const numberLat = Number(lat);
  const numberLon = Number(lon);

  if (lat && lon && typeof numberLat === 'number' && typeof numberLon === 'number') {
    return {
      lat: numberLat,
      lon: numberLon,
    };
  }
  return undefined;
};

export const getValidBounds = (bounds: TBounds | any[] = []): TBounds | undefined => {
  if (bounds.every((b) => !isNaN(Number(b)))) {
    return bounds as TBounds;
  }

  return undefined;
};

export const getLonLatMoveByOffset = ({
  lonLat,
  offset,
  map,
}: {
  lonLat?: TLonLat;
  offset?: Point;
  map?: Vsm.Map;
}) => {
  if (!(lonLat && offset && map)) {
    return {};
  }
  const vsmLonLat = convertToVSMPosition(lonLat);
  const point = vsmLonLat && map?.getTransform()?.lngLatToScreenPoint(vsmLonLat);
  let offsetCenter: TLngLat | undefined = vsmLonLat;

  if (point && offset) {
    const newPoint = {
      x: point.x + offset.x,
      y: point.y + offset.y,
    };
    const lngLat = map?.getTransform()?.screenPointToLngLat(newPoint);

    offsetCenter = {
      lat: lngLat.lat,
      lng: lngLat.lng,
    };
  }

  return {
    originalLonLat: lonLat,
    offsetLonLat: offsetCenter && convertToLonLat(offsetCenter),
  };
};

export const getOffsetCenter = ({map, offset}) => {
  const lonLat = convertToLonLat(map?.getCamera().getCenter());
  const center = getLonLatMoveByOffset({map, offset, lonLat});

  return center;
};

export const addressInfoFromReverseGeoData = (data: TReverseGeocoding, isUseJibun?: boolean) => {
  const bigRegion = [data?.regionName1 || '', data?.regionName2 || ''].join(' ');

  const jibun = [bigRegion, data?.regionName3 || '', data?.regionName4 || '', data?.bunji || '']
    .filter((v) => !!v.trim())
    .join(' ');

  const road = [bigRegion, data?.roadName || '', data?.roadBunji || '']
    .filter((v) => !!v.trim())
    .join(' ');

  const priority = isUseJibun ? jibun : road;
  const sub = isUseJibun ? road : jibun;
  const absoluteExist = bigRegion !== priority ? priority : sub;

  const depth2Jibun = [
    data?.regionName2 || '',
    data?.regionName3 || '',
    data?.regionName4 || '',
    data?.bunji || '',
  ]
    .filter((v) => !!v.trim())
    .join(' ');

  const depth2Road = [data?.regionName2 || '', data?.roadName || '', data?.roadBunji || '']
    .filter((v) => !!v.trim())
    .join(' ');

  return {
    jibun,
    road,
    depth2Jibun,
    depth2Road,
    hasJibunAddress: bigRegion !== jibun,
    hasRoadAddress: bigRegion !== road,

    priority,
    sub,
    absoluteExist,
  };
};

export const checkIsActiveItem = (a, b) => {
  if (!a || !b) {
    return false;
  }

  return (
    a.personalPoiKey === b.personalPoiKey ||
    (!!a.poiId && !!b.poiId && a.poiId === b.poiId) ||
    (!!a.pkey && !!b.pkey && a.pkey === b.pkey) ||
    (a.centerX === b.centerX && a.centerY === b.centerY)
  );
};

// App <-> Web 간 조정되어야 할 레벨.
// App -> Web일때 +6
// Web -> app -6
// ios 6 ~ 18
// and 0 ~ 12
export const convertWebToAppZoomLevel = (webZoomLevel) => {
  const zoom = Number(webZoomLevel);

  return isNaN(zoom) ? undefined : zoom - MAP_LEVEL_ADJUST_VALUE;
};

export const convertAppToWebZoomLevel = (appZoomLevel) => {
  const zoom = Number(appZoomLevel);

  return isNaN(zoom) ? undefined : zoom + MAP_LEVEL_ADJUST_VALUE;
};

export const getMapScale = (fontSize: EMapFontSize) => {
  const {fontScale, iconScale} =
    {
      [EMapFontSize.LARGE]: Vsm.Extensions.TmapUtils.POI_SCALE.large,
      [EMapFontSize.SMALL]: Vsm.Extensions.TmapUtils.POI_SCALE.small,
      [EMapFontSize.NORMAL]: Vsm.Extensions.TmapUtils.POI_SCALE.normal,
    }[fontSize] || {};

  return {fontScale, iconScale};
};

export const compareFavorite = (a: TFavoriteItem, b: TFavoriteItem) => {
  if (a.stationId && a.poiId) {
    return `${a.poiId}` === `${b.poiId}`;
  }
  // 22.12 앱에서 정의한 pkey 있을때 pkey만. 없을때 입구점만 비교 적용
  else if (a.pkey && b.pkey) {
    return `${a.pkey}` === `${b.pkey}`;
  } else {
    return `${a.navX}` === `${b.navX}` && `${a.navY}` === `${b.navY}`;
  }
};

// https://github.com/Turfjs/turf/tree/master/packages/turf-distance
export const getDistanceBetweenCoords = (from: TLonLat, to: TLonLat) => {
  return turfDistance([from.lon, from.lat], [to.lon, to.lat]);
};

export const parsePersonalMarkerType = (type: EMarkerType) => {
  if ([EMarkerType.FAVORITE, EMarkerType.ACTIVE_FAVORITE].includes(type)) {
    return EMarkerType.ACTIVE_FAVORITE;
  }
  if (
    [EMarkerType.FAVORITE_PUBLIC_TRANS, EMarkerType.ACTIVE_FAVORITE_PUBLIC_TRANS].includes(type)
  ) {
    return EMarkerType.ACTIVE_FAVORITE_PUBLIC_TRANS;
  }
  if ([EMarkerType.FAVORITE_HOME, EMarkerType.ACTIVE_FAVORITE_HOME].includes(type)) {
    return EMarkerType.ACTIVE_FAVORITE_HOME;
  }
  if ([EMarkerType.FAVORITE_OFFICE, EMarkerType.ACTIVE_FAVORITE_OFFICE].includes(type)) {
    return EMarkerType.ACTIVE_FAVORITE_OFFICE;
  }
  if ([EMarkerType.RECENT_DESTINATION, EMarkerType.ACTIVE_RECENT_DESTINATION].includes(type)) {
    return EMarkerType.ACTIVE_RECENT_DESTINATION;
  }

  return EMarkerType.NONE;
};
