import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {Age, Gender, TAroundMeSelectableParams, TimeOfDay, TRadius} from 'ducks/tplacehome/types';

import BottomSheetPopup from 'components/tplacehome/shared/BottomSheetPopup';
import s from 'styles/components/tplacehome/TPlacePopupBase.module.scss';
import classNames from 'classnames';
import BottomSheetPopupButtonSet from 'components/tplacehome/shared/BottomSheetPopupButtonSet';
import {getAroundMeDefaultSelectableParams} from 'ducks/tplacehome/slice';
import usePlaceHome from 'hooks/usePlaceHome';

type TProps = {
  isOpen: boolean;
  onClickSubmit: (params: TAroundMeSelectableParams) => void;
};

const ParamSelectPopup = ({isOpen, onClickSubmit}: TProps) => {
  const {tplacehome} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();
  const selectedParams = useMemo(
    () => tplacehome.aroundMeData.selectedParams,
    [tplacehome.aroundMeData.selectedParams]
  );

  const [radius, setRadius] = useState(selectedParams.radius);
  const [timeOfDay, setTimeOfDay] = useState(selectedParams.timeOfDay);
  const [gender, setGender] = useState(selectedParams.gender);
  const [age, setAge] = useState(selectedParams.age);

  const optionInfo = useMemo<{
    radius: {label: string; value: TRadius}[];
    timeOfDay: {label: string; value: TimeOfDay}[];
    gender: {label: string; value: Gender}[];
    age: {label: string; value: Age}[];
  }>(() => {
    return {
      radius: [
        {label: '500m', value: 500},
        {label: '1km', value: 1000},
        {label: '5km', value: 5000},
        {label: '10km', value: 10000},
      ],
      timeOfDay: [
        {label: '아침/오전', value: 'morning'},
        {label: '점심', value: 'lunch'},
        {label: '오후', value: 'afternoon'},
        {label: '저녁', value: 'dinner'},
      ],
      gender: [
        {label: '남자', value: 'm'},
        {label: '여자', value: 'f'},
      ],
      age: [
        {label: '20대', value: 20},
        {label: '30대', value: 30},
        {label: '40대', value: 40},
        {label: '50대', value: 50},
        {label: '60대 이상', value: 60},
      ],
    };
  }, []);

  // reset
  const resetStateByStore = useCallback(() => {
    setRadius(selectedParams.radius);
    setTimeOfDay(selectedParams.timeOfDay);
    setGender(selectedParams.gender);
    setAge(selectedParams.age);
  }, [selectedParams]);
  useEffect(() => {
    isOpen && resetStateByStore();
  }, [isOpen, resetStateByStore]);

  const resetStateByDefaults = useCallback(() => {
    const defaultData = getAroundMeDefaultSelectableParams();
    setRadius(defaultData.radius);
    setTimeOfDay(defaultData.timeOfDay);
    setGender(defaultData.gender);
    setAge(defaultData.age);
  }, []);

  return (
    <BottomSheetPopup
      isOpen={isOpen}
      contentComponent={
        <div className={classNames(s.content_wrap, s.blue)}>
          <div className={s.button_group}>
            <div className={s.title}>반경 설정</div>
            <div className={s.list}>
              {optionInfo.radius.map((item) => (
                <button
                  key={item.value}
                  onClick={() => setRadius(item.value)}
                  className={classNames(s.rounded_button, {[s.checked]: item.value === radius})}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
          <div className={s.button_group}>
            <div className={s.title}>방문 시간대 선택</div>
            <div className={s.list}>
              {optionInfo.timeOfDay.map((item) => (
                <button
                  key={item.value}
                  onClick={() => setTimeOfDay(item.value)}
                  className={classNames(s.rounded_button, {[s.checked]: item.value === timeOfDay})}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
          <div className={s.button_group}>
            <div className={s.title}>성별</div>
            <div className={s.list}>
              {optionInfo.gender.map((item) => (
                <button
                  key={item.value}
                  onClick={() => setGender(item.value)}
                  className={classNames(s.rounded_button, {[s.checked]: item.value === gender})}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
          <div className={s.button_group}>
            <div className={s.title}>연령</div>
            <div className={s.list}>
              {optionInfo.age.map((item) => (
                <button
                  key={item.value}
                  onClick={() => setAge(item.value)}
                  className={classNames(s.rounded_button, {[s.checked]: item.value === age})}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      }
      bottomComponent={
        <BottomSheetPopupButtonSet
          onClickReset={() => resetStateByDefaults()}
          onClickConfirm={() => {
            const params = {
              radius,
              timeOfDay,
              gender,
              age,
            };
            placehomeHook.sendEvent('tap.filter_ranking_popup_select', {
              tab_ranking_filter: params,
            });
            onClickSubmit(params);
          }}
        />
      }
    />
  );
};

export default ParamSelectPopup;
