import {useMemo, useEffect, useCallback, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import TMapSender from '@lcc/tmap-inapp';

import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';

import {EPlaceCategoryType, ESkeletonType} from 'types/App';
import {EButtonType} from 'types/Button';
import {EListMode, TListDrawerResult} from 'types/ListDrawer';
import {TLonLat} from 'types/Map';
import {EPerformanceLogKey} from 'types/Log';
import {TAddressMap} from 'constant/Address';

import {CATEGORY_SHORT_ADDRESS_KEY, CATEGORY_SHORT_ADDRESS_VALUE} from 'constant/Poi';
import {
  HEADER_CATEGORY_LIST_MARGIN,
  TITLE_AREA_HEIGHT,
  TPLACE_RANKING_LIST_POSITION_HEIGHT,
  TPLACE_RANKING_TITLE_AREA_HEADER_MARGIN,
  TPLACE_RANKING_FILTER_HEIGHT,
  MAX_MARKER_WIDTH,
  MAX_MARKER_TITLE_HEIGHT,
} from 'constant/Size';
import {EActionId, EPageType, TLA_PAGE_ID} from 'constant/Log';

import useUserData from 'hooks/useUserData';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {usePerformanceLog} from 'hooks/usePerformanceLog';
import useMapOffset from 'hooks/useMapOffset';
import useLogger from 'hooks/useLogger';
import {useParamLog} from 'hooks/useParamLog';
import {useOnce} from 'hooks/useOnce';

import {VSMInterfaceProvider} from 'context/VSMInterfaceContext';

import ua from 'utils/uaParser';
import {getValidLonLat} from 'utils/map';
import {init as initLog} from 'utils/logManager';

import DrawerContainer from 'components/DrawerContainer';
import PlaceMap from 'components/PlaceMap';
import Header from 'components/Header';
import Skeleton from 'components/Skeleton';
import VSMCompass from 'components/VSMCompass';
import BuildInfo from 'components/BuildInfo';
import RouteAddPopup from 'components/RouteAddPopup';
import ErrorReload from 'components/ErrorReload';
import {useValidCategory} from 'hooks/useValidCategory';
import {EAddressMode} from 'types/Search';
import {callShareInterface} from 'utils/tmapInApp';
import SearchRankingFilter from 'components/search/SearchRankingFilter';
import SearchRankingDropDown, {THREE_DEPTH_REGION} from 'components/search/SearchRankingDropDown';
import SearchRankingListHeader from 'components/search/SearchRankingListHeader';
import SearchRankingHeaderQuickFilter from 'components/search/SearchRankingHeaderQuickFilter';
import SearchTmapAI from 'components/search/SearchTmapAI';
import RankingList from 'components/ranking/RankingList';
import RankingCardListMode from 'components/ranking/RankingCardListMode';
import {fetchTRankList} from 'ducks/rank/slice';
import {getAddressMapFromAreaCode, getAddressMapFromReverseGeo} from 'utils/special';
import useTplaceReverseGeocoding from 'hooks/useTplaceReverseGeocoding';
import {IcoArrowRight} from 'components/@tmds/icons/IcoArrowRight';
import {generateShortUrl, getBridgeLink} from 'utils/url';
import useListScroll from 'hooks/useListScroll';
import {cityCoordinates, ECity} from 'constant/Map';
import classNames from 'classnames';
import RankingCurrentPositionButton from 'components/RankingCurrentPositionButton';

import s from 'styles/pages/RankingPage.module.scss';

const DRAWER_FLOATING_WRAP_HEIGHT = 40;
const DRAWER_FLOATING_WRAP_PADDING = 16;
const GAP_BETWEEN_LIST_AND_MAP = 20;
const RANKING_LIST_EXTRA_MARGIN = 12;

export enum EViewMode {
  RANKING = 'ranking',
  PHOTO = 'photo',
}

const BRIDGE_LINK = getBridgeLink('nearby', {
  reqKey: EPlaceCategoryType.RANKING,
  shareTitle: '티맵랭킹',
  shareContents: '전국 인기 장소가 궁금할 때 티맵랭킹',
});

const RankingPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {queries, tailParam} = useParseQueryLocation();
  const {
    userPosition,
    calloutInfo,
    userInteraction,
    rdUserInfo,
    windowSize,
    tRank,
    tRankList,
    isLogInitialized,
    activePoi,
    rdLayout,
    currentAddressMap,
    nowCenter,
    initPitch,
    initBearing,
    sortOption,
  } = useAppSelector((state) => ({
    userPosition: state.map.userPosition,
    tRank: state.tRank,
    tRankList: state.tRank.data.list,
    calloutInfo: state.userInteraction.calloutInfo,
    userInteraction: state.userInteraction,
    rdUserInfo: state.userInfo,
    windowSize: state.layout.windowSize,
    isLogInitialized: state.log.isInitialize,
    activePoi: state.userInteraction.activeTargetPoi,
    rdLayout: state.layout,
    currentAddressMap: state.tRank.currentAddressMap,
    nowCenter: state.map.nowCenter,
    initPitch: state.map.nowPitch,
    initBearing: state.map.nowBearing,
    sortOption: state.tRank.activeSortOption,
  }));
  const {normalLog} = usePerformanceLog();
  const {sendPlacePageLog, sendPlaceResultLog, sendClickLogWithMapView, sendClickLog} = useLogger();
  const {referrer} = useParamLog();
  useUserData(); // 즐겨찾기 이슈, page Load시 useUserData 호출 이슈 수정후 제거

  const [initLoad, setInitLoad] = useState(false);
  const headerMarginTop = useMemo(
    () => TITLE_AREA_HEIGHT + HEADER_CATEGORY_LIST_MARGIN + TPLACE_RANKING_FILTER_HEIGHT,
    []
  );

  const {centerOffset, getBoundsPaddingAndOffset} = useMapOffset();
  const {handleListScrollTop} = useListScroll({list: tRank.displayList, apiStatus: tRank});
  const validCenter = useMemo(
    () => getValidLonLat({lat: queries.centerLat, lon: queries.centerLon}) || nowCenter,
    [queries, nowCenter]
  );

  const validUserPos = useMemo(
    () => getValidLonLat({lat: queries.userLat, lon: queries.userLon}) || userPosition,
    [queries, userPosition]
  );
  const hasValidRegion = useMemo(
    () => !!queries.region && !!cityCoordinates[queries.region],
    [queries]
  );

  const [prevCenter, setPrevCenter] = useState<TLonLat | undefined>();
  const refRankingFilter = useRef<HTMLDivElement>(null);

  const {target, isValidCheckFinish} = useValidCategory(queries);

  const [filterAddressMap, setFilterAddressMap] = useState<TAddressMap>({});
  const [viewMode, setViewMode] = useState<EViewMode>(EViewMode.RANKING);
  const [isCardMode, setIsCardMode] = useState<boolean>(false);

  const isFromSearchRecommend = useMemo(
    () => queries.redirect_from === 'search_recommend',
    [queries]
  );

  const reverseGeo = useTplaceReverseGeocoding(
    isFromSearchRecommend
      ? {
          lat: validUserPos?.lat,
          lon: validUserPos?.lon,
        }
      : {
          lat: validCenter?.lat,
          lon: validCenter?.lon,
        }
  );

  useEffect(() => {
    if (!initLoad && tRank.loaded) {
      setInitLoad(true);
    }
  }, [initLoad, tRank.loaded]);

  useUserData({ignorePosition: true});

  const isDropDownOpen = useMemo(() => !!filterAddressMap[EAddressMode.CATE1], [filterAddressMap]);

  const getList = useCallback(
    (pagingParam = {}) => {
      const areaCode1 = currentAddressMap[EAddressMode.CATE1]?.areaDepth1Code;
      const areaCode2 = currentAddressMap[EAddressMode.CATE2]?.areaDepth2Code;
      const areaCode3 = currentAddressMap[EAddressMode.CATE3]?.areaDepth3Code;

      dispatch(actions.tRank.resetFilter());
      dispatch(actions.tRank.resetSort());
      dispatch(actions.tRank.resetList());
      dispatch(actions.userInteraction.setDragMap(false));
      return dispatch(fetchTRankList({areaCode1, areaCode2, areaCode3, ...pagingParam}));
    },
    [dispatch, currentAddressMap]
  );

  useOnce(!!rdUserInfo.accessKey && target, () => {
    initLog({
      sessionId: rdUserInfo.sessionId,
      accessKey: rdUserInfo.accessKey,
      sessionKey: rdUserInfo.sessionKey,
      userKey: rdUserInfo.userKey,
      deviceId: rdUserInfo.device.deviceId,
      carrierName: rdUserInfo.device.carrierName,
      pageId: `${TLA_PAGE_ID}/${target?.type || ''}`,
      pageType: EPageType.PLACE_CATEGORY,
      title: '티맵랭킹',
      referrer,
    });

    dispatch(actions.log.setInitialize(true));
    normalLog(EPerformanceLogKey.WEB_LAUNCH);
  });

  useOnce(isLogInitialized && tRank.loaded, () => {
    sendPlacePageLog(target?.title);
  });

  useOnce(tRank.loaded, () => {
    sendPlaceResultLog(target?.title);
  });

  useEffect(() => {
    const isShortAddress =
      parseInt(tailParam?.[CATEGORY_SHORT_ADDRESS_KEY], 10) ===
      parseInt(CATEGORY_SHORT_ADDRESS_VALUE, 10);

    dispatch(actions.category.setShowShortAddress(isShortAddress));

    return () => {
      dispatch(actions.place.reset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const activeId = useMemo(() => {
    if (userInteraction.activePoi && ['marker', 'rotate'].includes(userInteraction.trigger || '')) {
      return userInteraction.activePoi;
    }
    return undefined;
  }, [userInteraction]);

  useEffect(() => {
    if ((!activeId && !isCardMode) || rdLayout.appSize.isLandscape) {
      return;
    }

    // TODO: marker 노출 렌더링 확인. 현재 미노출
    setIsCardMode(true);
  }, [activePoi, isCardMode, rdLayout.appSize.isLandscape]);

  useEffect(() => {
    if (!tRank.loaded) {
      return;
    }

    handleListScrollTop();
  }, [tRank.loaded, sortOption, viewMode]);

  const handleGoBack = useCallback(() => {
    if (calloutInfo) {
      dispatch(actions.userInteraction.clearCalloutInfo());
      dispatch(actions.userInteraction.setInteraction({drawerMode: EListMode.CENTER}));
    } else {
      if (ua.isInApp) {
        TMapSender.onBackKeyPressed();
      } else {
        history.goBack();
      }
    }
  }, [dispatch, calloutInfo, history]);

  const handleClickShare = useCallback(async () => {
    const shortUrl = await generateShortUrl(BRIDGE_LINK);

    sendClickLog(EActionId.TAP_SHARE);
    callShareInterface(
      {title: '[TMAP]', text: '티맵랭킹', url: shortUrl},
      {onSuccess: () => {}, onFail: () => {}}
    );
  }, []);

  useOnce(!hasValidRegion && reverseGeo.loaded, () => {
    const {region1Code, region2Code, region3Code, region1Name} = reverseGeo.data || {};
    const isMainCity = THREE_DEPTH_REGION.includes(region1Name || '');

    if (region1Code && region2Code && region3Code) {
      getList({
        areaCode1: region1Code,
        areaCode2: region2Code,
        areaCode3: isMainCity ? region3Code : '',
      });

      const newAddressMap = reverseGeo.data && getAddressMapFromReverseGeo(reverseGeo.data);
      dispatch(actions.tRank.updateCurrentAddressMap(newAddressMap));

      return;
    }
  });

  useOnce(validCenter && validUserPos, () => {
    setPrevCenter(validCenter);
    validUserPos && dispatch(actions.map.setUserPosition(validUserPos));
    validCenter && dispatch(actions.map.setNowCenter(validCenter));
    validCenter &&
      dispatch(
        actions.map.setLastCachedCenter({lat: validCenter.lat, lon: validCenter.lon, from: 'map'})
      );
  });

  useOnce(hasValidRegion, () => {
    const region = queries.region as ECity;
    const areaInfo = cityCoordinates[region];

    if (areaInfo) {
      getList(areaInfo);
      dispatch(actions.tRank.updateCurrentAddressMap(getAddressMapFromAreaCode(areaInfo)));
    }
  });

  useEffect(() => {
    if (!isDropDownOpen) {
      return;
    }

    dispatch(actions.userInteraction.setInteraction({drawerMode: EListMode.CENTER}));
  }, [isDropDownOpen]);

  if (!target && isValidCheckFinish) {
    return (
      <div className={s.error_wrap}>
        <div className={s.header}>
          <Header
            leftButton={EButtonType.BACK}
            onGoBack={handleGoBack}
            onClickLeft={() => {
              sendClickLogWithMapView(EActionId.TAP_BACK);
            }}
          />
        </div>
        <ErrorReload />
      </div>
    );
  }

  return (
    <VSMInterfaceProvider>
      <div
        className={classNames(s.header, {
          [s.z_index_medium]: isDropDownOpen || (rdLayout.appSize.isLandscape && !calloutInfo),
          [s.z_index_high]: rdLayout.appSize.isLandscape && !!calloutInfo,
        })}
      >
        <Header
          leftButton={EButtonType.BACK}
          onGoBack={handleGoBack}
          onClickLeft={() => {
            sendClickLogWithMapView(EActionId.TAP_BACK);
          }}
          rightButton={EButtonType.SHARE}
          onClickRight={handleClickShare}
          title="티맵랭킹"
        />
        <Skeleton type={ESkeletonType.SEARCH_RANKING_DROPDOWN} apiStatus={tRank}>
          <SearchRankingFilter
            filterAddressMap={filterAddressMap}
            onClick={setFilterAddressMap}
            ref={refRankingFilter}
            apiStatus={tRank}
          />

          {isDropDownOpen && (
            <SearchRankingDropDown
              filterAddressMap={filterAddressMap}
              setFilterAddressMap={setFilterAddressMap}
              ignoreRef={refRankingFilter}
              getList={getList}
            />
          )}
        </Skeleton>

        <VSMCompass className={s.vsm_compass} />
      </div>

      <RankingCardListMode list={tRankList} hide={!isCardMode} setCardMode={setIsCardMode} />

      <DrawerContainer
        isHideToTop={true}
        showFixedTopBtn={true}
        isPadColorWhite={true}
        list={tRankList}
        isLastPage={true}
        listMode={userInteraction.drawerMode}
        onChangeListMode={(drawerMode) => {
          dispatch(actions.userInteraction.setInteraction({drawerMode}));
        }}
        renderCurrentPosition={({onAfterPosition, disableAutoMove}) => {
          return (
            <RankingCurrentPositionButton
              onAfterPosition={onAfterPosition}
              disableAutoMove={disableAutoMove}
            />
          );
        }}
        mapComponent={(drawerProps: TListDrawerResult) => {
          const {boundsPadding} = getBoundsPaddingAndOffset(drawerProps, {
            headerHeight:
              TITLE_AREA_HEIGHT +
              TPLACE_RANKING_TITLE_AREA_HEADER_MARGIN +
              GAP_BETWEEN_LIST_AND_MAP +
              GAP_BETWEEN_LIST_AND_MAP,
            maxMarkerHeight: 0,
            maxMarkerWidth: MAX_MARKER_WIDTH,
            maxMarkerTitleHeight: MAX_MARKER_TITLE_HEIGHT,
            bottomPadding:
              DRAWER_FLOATING_WRAP_HEIGHT + DRAWER_FLOATING_WRAP_PADDING + GAP_BETWEEN_LIST_AND_MAP,
          });

          return initLoad && boundsPadding && validUserPos ? (
            <PlaceMap
              boundsPadding={boundsPadding}
              list={tRankList}
              initLoad={initLoad}
              defaultCenter={prevCenter}
              initPitch={initPitch}
              initBearing={initBearing}
              markerClickToCenter={true}
              centerOffset={centerOffset}
            />
          ) : (
            <></>
          );
        }}
        listHeaderComponent={
          // TODO: SKeleton 적용
          <SearchRankingListHeader
            viewMode={viewMode}
            onClickViewMode={(view) => setViewMode(view)}
          />
        }
        listComponent={
          <>
            <SearchRankingHeaderQuickFilter />
            <div className={s.header_banner}>
              <SearchTmapAI
                tooltipContents={
                  <div>
                    티맵 AI가 티맵 고객의 이동 데이터를 <br />
                    분석해 선정한 인기 장소입니다.
                  </div>
                }
                link={{
                  url: 'tmap://board?pageid=board_detail&boardid=BOARD-MAIN&seq=184&top=true',
                  text: (
                    <div className={s.link}>
                      자세히 보기 <IcoArrowRight width={14} height={14} color={'tmobiBlue600'} />
                    </div>
                  ),
                }}
              />
            </div>
            <Skeleton type={ESkeletonType.PLACE_RESULT_LIST} apiStatus={tRank}>
              <RankingList
                mode={viewMode}
                apiStatus={tRank}
                getList={getList}
                onReload={() => {
                  window.location.reload();
                  sendClickLog('tap.fail_refresh');
                }}
              />
            </Skeleton>
          </>
        }
        drawerOptions={{
          centerHeight: parseInt(
            `${
              windowSize.height -
              (TITLE_AREA_HEIGHT + TPLACE_RANKING_TITLE_AREA_HEADER_MARGIN) -
              TPLACE_RANKING_LIST_POSITION_HEIGHT
            }`,
            // list, cardMode 고정값이라 percentage 적용 안하고 고정값으로 적용
            10
          ),
          topAreaHeight: TITLE_AREA_HEIGHT + TPLACE_RANKING_TITLE_AREA_HEADER_MARGIN,
        }}
        paddingTop={headerMarginTop}
        extraMargin={headerMarginTop - RANKING_LIST_EXTRA_MARGIN}
        hide={isCardMode}
        setHide={() => {
          setIsCardMode(false);
          dispatch(actions.userInteraction.resetListInteraction());
        }}
        refreshButtonVisible={false}

        // TODO: 가로 모드시 height 조정 필요.
      />

      <RouteAddPopup />

      <BuildInfo />
    </VSMInterfaceProvider>
  );
};

export default RankingPage;
